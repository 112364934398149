import React from "react"
import HomeStyles from "./HomePage.module.scss"

export default function HomePageDesktop() {
  return (
    <svg
      aria-labelledby="homePageSvg"
      role="img"
      width="90%"
      height="100%"
      viewBox="0 0 1176 555"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="homePageSvg">
        Home page svg with desk and shorter desk with cloud, sparkles and test
        tube bubbles animation.
      </title>
      <path
        opacity=".3"
        d="M693.1 374.7H17.6c-6.6 0-12-5.4-12-12V17.6c0-6.6 5.4-12 12-12h675.5c6.6 0 12 5.4 12 12v345.1c0 6.6-5.4 12-12 12z"
        fill="#EFE9E2"
        stroke="#676767"
        strokeWidth="11.162"
        strokeMiterlimit="10"
      />
      <g opacity=".3">
        <path
          d="M1012.5 354.3h25.7v79.4c0 .1.5 7.1-3.3 11.7-2.1 2.5-5.1 3.9-8.9 4.1h-.9c-3.6 0-6.5-1.1-8.6-3.3-3.9-4-4-10.3-4-10.4v-81.5z"
          fill="#F5F5F5"
        />
        <path
          d="M1011.7 354.5h27.4c2 0 3.7-1.7 3.7-3.7v-1.2c0-2-1.7-3.7-3.7-3.7h-27.4c-2 0-3.7 1.7-3.7 3.7v1.2c0 2.1 1.7 3.7 3.7 3.7z"
          fill="#F5F5F5"
        />
        <path
          d="M1025.5 450.2c7.1 0 12.9-5.8 12.9-12.9v-52.9h-25.9v52.9c.1 7.1 5.9 12.9 13 12.9z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M1025.5 386.6c7.12 0 12.9-.985 12.9-2.2 0-1.215-5.78-2.2-12.9-2.2s-12.9.985-12.9 2.2c0 1.215 5.78 2.2 12.9 2.2z"
          fill="#B3DAD0"
        />
        <path
          opacity=".27"
          d="M1017.4 354.3V436.6c0 .1 0 6.4 4.1 10.5 1.7 1.7 3.8 2.7 6.2 3.1-.6.1-1.2.2-1.8.2h-.9c-3.7 0-6.6-1.1-8.8-3.3-4-4-4.1-10.4-4.1-10.5v-82.3h5.3z"
          fill="#D8D8D8"
        />
        <path
          opacity=".28"
          d="M1033.3 425.4c1.5 0 2.8-1.3 2.8-2.8v-31c0-1.5-1.3-2.8-2.8-2.8-1.5 0-2.8 1.3-2.8 2.8v31c0 1.6 1.3 2.8 2.8 2.8z"
          fill="#D8D8D8"
        />
        <path
          d="M1013.3 339.1c.3 2.6 1.3 5.2 3.1 7.6 4.6 6.3 12.6 8.3 17.8 4.5.6-.4 1.1-.9 1.5-1.4l-1.2 6.8c-.3 1.6-1.5 2.8-2.8 2.8h-12.5c-1.4 0-2.5-1.2-2.8-2.8l-3-16.3c-.1-.3-.1-.8-.1-1.2z"
          fill="#9A5C39"
        />
        <path
          d="M1034.2 351.2c-5.2 3.8-13.1 1.8-17.8-4.5-1.7-2.4-2.8-5-3.1-7.6.1-.8.4-1.5.9-2.1 1.8 2 6.1 3.4 11.2 3.4 5 0 9.4-1.4 11.2-3.4.7.8 1.1 2.1.8 3.4l-1.7 9.4c-.5.6-1 1-1.5 1.4z"
          fill="#C67B56"
        />
        <path
          d="M1025.4 340.4c-5.1 0-9.4-1.4-11.2-3.4.5-.6 1.2-1 2-1h18.3c.8 0 1.5.4 2 1-1.7 2-6.1 3.4-11.1 3.4z"
          fill="#DC9F58"
        />
        <path
          d="M1033.3 343.7c.39 0 .7-.313.7-.7 0-.387-.31-.7-.7-.7-.39 0-.7.313-.7.7 0 .387.31.7.7.7zM1028.5 351.6c.72 0 1.3-.582 1.3-1.3 0-.718-.58-1.3-1.3-1.3-.72 0-1.3.582-1.3 1.3 0 .718.58 1.3 1.3 1.3zM1021.4 343.7c.28 0 .5-.224.5-.5s-.22-.5-.5-.5-.5.224-.5.5.22.5.5.5zM1019 347.6c.28 0 .5-.224.5-.5s-.22-.5-.5-.5-.5.224-.5.5.22.5.5.5zM1033.4 347.7a.601.601 0 000-1.2.601.601 0 000 1.2zM1028.5 345.4a.601.601 0 000-1.2.601.601 0 000 1.2zM1023.4 347.8c.39 0 .7-.313.7-.7 0-.387-.31-.7-.7-.7-.39 0-.7.313-.7.7 0 .387.31.7.7.7zM1016.5 342.7c.5 0 .9-.403.9-.9s-.4-.9-.9-.9-.9.403-.9.9.4.9.9.9z"
          fill="#9A5C39"
        />
        <path
          d="M1019.5 356a.801.801 0 000-1.6.801.801 0 000 1.6zM1024.5 357.3c.77 0 1.4-.627 1.4-1.4 0-.773-.63-1.4-1.4-1.4-.77 0-1.4.627-1.4 1.4 0 .773.63 1.4 1.4 1.4z"
          fill="#C67B56"
        />
        <path
          opacity=".27"
          d="M1011.8 354.6h27.4c2 0 3.7-1.7 3.7-3.7v-1.2c0-2-1.7-3.7-3.7-3.7h-27.4c-2 0-3.7 1.7-3.7 3.7v1.2c.1 2 1.7 3.7 3.7 3.7z"
          fill="#D8D8D8"
        />
        <g>
          <path
            d="M1059.1 482.6h2.4c2.9 0 5.2-2.4 5.2-5.2V352.2c0-2.9-2.4-5.2-5.2-5.2h-2.4c-2.9 0-5.2 2.4-5.2 5.2v125.1c-.1 2.9 2.3 5.3 5.2 5.3z"
            fill="url(#paint1_linear)"
          />
          <path
            d="M982.2 487.1h95.7c1.7 0 3.1-1.4 3.1-3.1 0-6.8-5.6-12.4-12.4-12.4h-77c-6.8 0-12.4 5.6-12.4 12.4-.1 1.7 1.3 3.1 3 3.1z"
            fill="url(#paint2_linear)"
          />
          <path
            opacity=".28"
            d="M989.5 471.6h16.8c-5.7 0-10.4 4-10.4 8.8v4.5c0 1.2 1.2 2.2 2.6 2.2h-16.8c-1.4 0-2.6-1-2.6-2.2v-4.5c0-4.9 4.7-8.8 10.4-8.8z"
            fill="url(#paint3_linear)"
          />
          <path
            d="M1074.1 371.8v-2.4c0-2.9-2.4-5.2-5.2-5.2h-74.7c-2.9 0-5.2 2.4-5.2 5.2v2.4c0 2.9 2.4 5.2 5.2 5.2h74.7c2.8 0 5.2-2.4 5.2-5.2z"
            fill="url(#paint4_linear)"
          />
          <path
            opacity=".28"
            d="M1060.3 373.1a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
            fill="#D8D8D8"
          />
        </g>
      </g>
      <g opacity=".3">
        <path
          d="M823.1 169.8c-3.4 0-6.2-2.8-6.2-6.2v-12h-26.6v12c0 3.4-2.8 6.2-6.2 6.2-3.2 0-5.8 2.6-5.8 5.8v70.6c0 6 4.9 10.8 10.8 10.8H818c6 0 10.8-4.9 10.8-10.8v-70.6c.1-3.2-2.5-5.8-5.7-5.8z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M818.4 151.7h-29.6c-1.1 0-2-.9-2-2V147c0-1.1.9-2 2-2h29.6c1.1 0 2 .9 2 2v2.7c0 1.1-.9 2-2 2z"
          fill="#3A65AE"
        />
        <path
          opacity=".1"
          d="M823.1 169.8c-3.4 0-6.2-2.8-6.2-6.2v-12h-10v12c0 3.4 2.8 6.2 6.2 6.2 3.2 0 5.8 2.6 5.8 5.8v70.6c0 6-4.9 10.8-10.8 10.8h10c6 0 10.8-4.9 10.8-10.8v-70.6c0-3.2-2.6-5.8-5.8-5.8z"
          fill="#D8D8D8"
        />
        <path
          d="M778.3 228.8h26.3c3.3 0 6-2.7 6-6v-23.5c0-3.3-2.7-6-6-6h-26.3v35.5z"
          fill="#3D3D3B"
        />
        <path
          d="M778.8 227h26.7c1.4 0 2.5-1 2.5-2.1v-27.6c0-1.2-1.1-2.1-2.5-2.1h-27.3"
          stroke="#fff"
          strokeWidth=".198"
          strokeMiterlimit="10"
        />
        <path
          opacity=".1"
          d="M817.6 145h-4.8c1.5 0 2.8 1.2 2.8 2.8v1.1c0 1.5-1.2 2.8-2.8 2.8h4.8c1.5 0 2.8-1.2 2.8-2.8v-1.1c0-1.5-1.3-2.8-2.8-2.8z"
          fill="#D8D8D8"
        />
      </g>
      <g opacity=".3">
        <path
          d="M921.8 158.3c-3.9 0-7-3.1-7-7v-13.5h-30.1v13.5c0 3.9-3.1 7-7 7-3.6 0-6.6 3-6.6 6.6v79.9c0 6.8 5.5 12.3 12.3 12.3h32.8c6.8 0 12.3-5.5 12.3-12.3v-79.9c-.1-3.7-3.1-6.6-6.7-6.6z"
          fill="url(#paint6_linear)"
        />
        <path
          opacity=".1"
          d="M921.8 158.3c-3.9 0-7-3.1-7-7v-13.5h-11.3v13.5c0 3.9 3.1 7 7 7 3.6 0 6.6 3 6.6 6.6v79.9c0 6.8-5.5 12.3-12.3 12.3h11.3c6.8 0 12.3-5.5 12.3-12.3v-79.9c0-3.7-3-6.6-6.6-6.6z"
          fill="#D8D8D8"
        />
        <path
          d="M871.1 225h30.5c3.3 0 6-2.7 6-6v-28.2c0-3.3-2.7-6-6-6h-30.5V225z"
          fill="#3D3D3B"
        />
        <path
          d="M871.7 223H902c1.5 0 2.8-1.1 2.8-2.4v-31.3c0-1.3-1.3-2.4-2.8-2.4h-30.9"
          stroke="#fff"
          strokeWidth=".224"
          strokeMiterlimit="10"
        />
        <g>
          <path
            d="M915.7 140.8h-31.9c-1.4 0-2.5-1.1-2.5-2.5v-8.1c0-1.4 1.1-2.5 2.5-2.5h31.9c1.4 0 2.5 1.1 2.5 2.5v8.1c-.1 1.4-1.2 2.5-2.5 2.5z"
            fill="#3A65AE"
          />
          <path
            d="M885.2 129.5v9.5M888.9 129.5v9.5M892.5 129.5v9.5M896 129.5v9.5M899.7 129.5v9.5M903.3 129.5v9.5M906.5 129.5v9.5M910.2 129.5v9.5M913.4 129.5v9.5M885.2 129.5v9.4M888.9 129.5v9.4"
            stroke="#1E1E1C"
            strokeWidth=".747"
            strokeMiterlimit="10"
          />
        </g>
        <path
          opacity=".1"
          d="M916.1 127.6h-12.8c3 0 5.4 2.5 5.4 5.5v2.2c0 3-2.4 5.5-5.4 5.5h12.8c1.1 0 2.1-1 2.1-2.1v-9.1c-.1-1.1-1-2-2.1-2z"
          fill="#D8D8D8"
        />
      </g>
      <g opacity=".3">
        <path
          d="M811.3 487.5h-31.9c-6.6 0-12-5.4-12-12v-77.3h55.9v77.3c0 6.6-5.4 12-12 12z"
          fill="#F5F5F5"
        />
        <path
          d="M818.3 487.5h-45.8c-2.8 0-5.1-2.3-5.1-5.1v-84.3h55.9v84.3c0 2.8-2.3 5.1-5 5.1z"
          fill="#F5F5F5"
        />
        <path
          d="M814.7 483.9h-38.6c-2.8 0-5-2.3-5-5v-49.8h48.7v49.8c-.1 2.7-2.3 5-5.1 5z"
          fill="url(#paint7_linear)"
        />
        <path
          d="M824.6 398.2h-58.5c-2.1 0-3.8-1.7-3.8-3.8v-2c0-2.1 1.7-3.8 3.8-3.8h58.5c2.1 0 3.8 1.7 3.8 3.8v2c0 2.1-1.7 3.8-3.8 3.8z"
          fill="#F5F5F5"
        />
        <path
          d="M795.4 431.8c13.421 0 24.3-1.209 24.3-2.7s-10.879-2.7-24.3-2.7c-13.42 0-24.3 1.209-24.3 2.7s10.88 2.7 24.3 2.7z"
          fill="#F5AFAE"
        />
        <path
          opacity=".27"
          d="M814 398.2v85.5c0 2.1-1.8 3.8-4 3.8h9.8c2.2 0 4-1.7 4-3.8v-85.5H814zM778.1 463.8c-2.1 0-3.9-1.8-3.9-3.9v-34.1c0-2.1 1.8-3.9 3.9-3.9 2.1 0 3.9 1.8 3.9 3.9v34.1c0 2.1-1.7 3.9-3.9 3.9z"
          fill="#D8D8D8"
        />
        <g fill="#1E1E1C">
          <path d="M790.1 411.6h-19.8v.4h19.8v-.4zM790.1 426.7h-19.8v.4h19.8v-.4zM790.1 441.8h-19.8v.4h19.8v-.4zM790.1 456.9h-19.8v.4h19.8v-.4zM790.1 472.1h-19.8v.4h19.8v-.4zM777.7 417h-7.1v.4h7.1v-.4zM777.7 413.7h-7.1v.4h7.1v-.4zM777.7 420.2h-7.1v.4h7.1v-.4zM777.7 423.4h-7.1v.4h7.1v-.4zM777.7 432.1h-7.1v.4h7.1v-.4zM777.7 428.8h-7.1v.4h7.1v-.4zM777.7 435.3h-7.1v.4h7.1v-.4zM777.7 438.6h-7.1v.4h7.1v-.4zM777.7 447.8h-7.1v.4h7.1v-.4zM777.7 444.5h-7.1v.4h7.1v-.4zM777.7 451h-7.1v.4h7.1v-.4zM777.7 454.2h-7.1v.4h7.1v-.4zM777.7 462.9h-7.1v.4h7.1v-.4zM777.7 459.6h-7.1v.4h7.1v-.4zM777.7 466.1h-7.1v.4h7.1v-.4zM777.7 469.4h-7.1v.4h7.1v-.4z" />
        </g>
        <g>
          <path
            d="M881.2 487.5h-31.9c-6.6 0-12-5.4-12-12v-77.3h55.9v77.3c0 6.6-5.4 12-12 12z"
            fill="#F5F5F5"
          />
          <path
            d="M888.1 487.5h-45.8c-2.8 0-5.1-2.3-5.1-5.1v-84.3h55.9v84.3c.1 2.8-2.2 5.1-5 5.1z"
            fill="#F5F5F5"
          />
          <path
            d="M884.5 483.9h-38.6c-2.8 0-5-2.3-5-5v-49.8h48.7v49.8c0 2.7-2.3 5-5.1 5z"
            fill="url(#paint8_linear)"
          />
          <path
            d="M894.5 398.2H836c-2.1 0-3.8-1.7-3.8-3.8v-2c0-2.1 1.7-3.8 3.8-3.8h58.5c2.1 0 3.8 1.7 3.8 3.8v2c0 2.1-1.7 3.8-3.8 3.8z"
            fill="#F5F5F5"
          />
          <path
            d="M865.2 431.8c13.421 0 24.3-1.209 24.3-2.7s-10.879-2.7-24.3-2.7c-13.421 0-24.3 1.209-24.3 2.7s10.879 2.7 24.3 2.7z"
            fill="#B3DAD0"
          />
          <path
            opacity=".27"
            d="M883.8 398.2v85.5c0 2.1-1.8 3.8-4 3.8h9.8c2.2 0 4-1.7 4-3.8v-85.5h-9.8zM848 463.8c-2.1 0-3.9-1.8-3.9-3.9v-34.1c0-2.1 1.8-3.9 3.9-3.9 2.1 0 3.9 1.8 3.9 3.9v34.1c0 2.1-1.8 3.9-3.9 3.9z"
            fill="#D8D8D8"
          />
          <g fill="#1E1E1C">
            <path d="M859.9 411.6h-19.8v.4h19.8v-.4zM859.9 426.7h-19.8v.4h19.8v-.4zM859.9 441.8h-19.8v.4h19.8v-.4zM859.9 456.9h-19.8v.4h19.8v-.4zM859.9 472.1h-19.8v.4h19.8v-.4zM847.5 417h-7.1v.4h7.1v-.4zM847.5 413.7h-7.1v.4h7.1v-.4zM847.5 420.2h-7.1v.4h7.1v-.4zM847.5 423.4h-7.1v.4h7.1v-.4zM847.5 432.1h-7.1v.4h7.1v-.4zM847.5 428.8h-7.1v.4h7.1v-.4zM847.5 435.3h-7.1v.4h7.1v-.4zM847.5 438.6h-7.1v.4h7.1v-.4zM847.5 447.8h-7.1v.4h7.1v-.4zM847.5 444.5h-7.1v.4h7.1v-.4zM847.5 451h-7.1v.4h7.1v-.4zM847.5 454.2h-7.1v.4h7.1v-.4zM847.5 462.9h-7.1v.4h7.1v-.4zM847.5 459.6h-7.1v.4h7.1v-.4zM847.5 466.1h-7.1v.4h7.1v-.4zM847.5 469.4h-7.1v.4h7.1v-.4z" />
          </g>
        </g>
        <g>
          <path
            d="M952.7 488.1h-31.9c-6.6 0-12-5.4-12-12v-77.3h55.9v77.3c0 6.6-5.4 12-12 12z"
            fill="#F5F5F5"
          />
          <path
            d="M959.6 488.1h-45.8c-2.8 0-5.1-2.3-5.1-5.1v-84.3h55.9V483c.1 2.8-2.2 5.1-5 5.1z"
            fill="#F5F5F5"
          />
          <path
            d="M956 484.4h-38.6c-2.8 0-5-2.3-5-5v-49.8H961v49.8c0 2.7-2.2 5-5 5z"
            fill="url(#paint9_linear)"
          />
          <path
            d="M966 398.7h-58.5c-2.1 0-3.8-1.7-3.8-3.8v-2c0-2.1 1.7-3.8 3.8-3.8H966c2.1 0 3.8 1.7 3.8 3.8v2c0 2.1-1.7 3.8-3.8 3.8z"
            fill="#F5F5F5"
          />
          <path
            d="M936.7 432.3c13.421 0 24.3-1.209 24.3-2.7s-10.879-2.7-24.3-2.7c-13.421 0-24.3 1.209-24.3 2.7s10.879 2.7 24.3 2.7z"
            fill="#F5AFAE"
          />
          <path
            opacity=".27"
            d="M955.3 398.7v85.5c0 2.1-1.8 3.8-4 3.8h9.8c2.2 0 4-1.7 4-3.8v-85.5h-9.8zM919.4 464.3c-2.1 0-3.9-1.8-3.9-3.9v-34.1c0-2.1 1.8-3.9 3.9-3.9 2.1 0 3.9 1.8 3.9 3.9v34.1c0 2.2-1.7 3.9-3.9 3.9z"
            fill="#D8D8D8"
          />
          <g fill="#1E1E1C">
            <path d="M931.4 412.1h-19.8v.4h19.8v-.4zM931.4 427.2h-19.8v.4h19.8v-.4zM931.4 442.3h-19.8v.4h19.8v-.4zM931.4 457.5h-19.8v.4h19.8v-.4zM931.4 472.6h-19.8v.4h19.8v-.4zM919 417.5h-7.1v.4h7.1v-.4zM919 414.3h-7.1v.4h7.1v-.4zM919 420.7h-7.1v.4h7.1v-.4zM919 424h-7.1v.4h7.1v-.4zM919 432.6h-7.1v.4h7.1v-.4zM919 429.4h-7.1v.4h7.1v-.4zM919 435.9h-7.1v.4h7.1v-.4zM919 439.1h-7.1v.4h7.1v-.4zM919 448.3h-7.1v.4h7.1v-.4zM919 445h-7.1v.4h7.1v-.4zM919 451.5h-7.1v.4h7.1v-.4zM919 454.8h-7.1v.4h7.1v-.4zM919 463.4h-7.1v.4h7.1v-.4zM919 460.2h-7.1v.4h7.1v-.4zM919 466.6h-7.1v.4h7.1v-.4zM919 469.9h-7.1v.4h7.1v-.4z" />
          </g>
        </g>
        <g>
          <path
            d="M774.1 478.8c-1.5-.5-2.3-2.2-1.7-3.7l53.8-150.2c.5-1.5 2.2-2.3 3.7-1.7 1.5.5 2.3 2.2 1.7 3.7l-53.8 150.2c-.6 1.4-2.3 2.2-3.7 1.7z"
            fill="#F5F5F5"
          />
          <path
            opacity=".27"
            d="M829.8 323.2c-.5-.2-1-.2-1.5 0 .6.9.7 2.3.2 3.6l-53.2 148.4c-.5 1.3-1.4 2.3-2.5 2.6.3.5.7.8 1.2 1 1.5.5 3.3-.6 4-2.6l53.2-148.4c.7-2 .1-4.1-1.4-4.6z"
            fill="#D8D8D8"
          />
        </g>
      </g>
      <g opacity=".3" fill="#EFE9E2">
        <path d="M519.2 425.6c-14.4 0-9.8-17.5-9.8-17.5H508s4.7 17.5-9.8 17.5c14.4 0 9.8 17.5 9.8 17.5h1.4s-4.7-17.5 9.8-17.5zM854.2 356.6c-14.4 0-9.8-17.5-9.8-17.5H843s4.7 17.5-9.8 17.5c14.4 0 9.8 17.5 9.8 17.5h1.4s-4.7-17.5 9.8-17.5zM1100.2 399.6c-14.4 0-9.8-17.5-9.8-17.5h-1.4s4.7 17.5-9.8 17.5c14.4 0 9.8 17.5 9.8 17.5h1.4s-4.7-17.5 9.8-17.5z" />
      </g>
      <g opacity=".3">
        <path
          d="M384 425.5c-2.4 0-4.4-2-4.4-4.4v-8.6h-19.1v8.6c0 2.4-2 4.4-4.4 4.4-2.3 0-4.2 1.9-4.2 4.2v50.7c0 4.3 3.5 7.8 7.8 7.8h20.8c4.3 0 7.8-3.5 7.8-7.8v-50.7c-.1-2.3-2-4.2-4.3-4.2z"
          fill="url(#paint10_linear)"
        />
        <path
          opacity=".1"
          d="M384 425.5c-2.4 0-4.4-2-4.4-4.4v-8.6h-7.2v8.6c0 2.4 2 4.4 4.4 4.4 2.3 0 4.2 1.9 4.2 4.2v50.7c0 4.3-3.5 7.8-7.8 7.8h7.2c4.3 0 7.8-3.5 7.8-7.8v-50.7c0-2.3-1.9-4.2-4.2-4.2z"
          fill="#D8D8D8"
        />
        <path
          d="M371.3 408.8h-2.6c-1.5 0-2.8-1.3-2.8-2.8v-15.4c0-2.2 1.8-4.1 4.1-4.1 2.2 0 4.1 1.8 4.1 4.1V406c0 1.6-1.3 2.8-2.8 2.8z"
          fill="#3A65AE"
        />
        <path
          opacity=".1"
          d="M370.5 386.5c-.4 0-.8.1-1.2.2 1.7.7 2.9 2.7 2.9 5.1v14.3c0 1.4-.9 2.6-2 2.6h2.4c1.1 0 2-1.2 2-2.6v-14.3c0-2.9-1.8-5.3-4.1-5.3z"
          fill="#D8D8D8"
        />
        <path d="M378.4 404.5h-16.8v2.7h16.8v-2.7z" fill="#3D3D3B" />
        <path
          opacity=".17"
          d="M370 478.7c-1.4 0-2.6-5.1-2.6-6.8v-58.4c0-1.7 1.2-3.1 2.6-3.1 1.4 0 2.6 1.4 2.6 3.1v58.4c0 1.7-1.2 6.8-2.6 6.8z"
          fill="#C95F1A"
        />
        <path
          d="M380 413.2h-20.1c-1.8 0-3.3-1.5-3.3-3.3 0-1.8 1.5-3.3 3.3-3.3H380c1.8 0 3.3 1.5 3.3 3.3 0 1.9-1.5 3.3-3.3 3.3z"
          fill="#3A65AE"
        />
        <path
          d="M351.7 466.3h17.2c3.3 0 6-2.7 6-6v-13.5c0-3.3-2.7-6-6-6h-17.2v25.5z"
          fill="#3D3D3B"
        />
        <path
          d="M352.1 465.1h19.2c1 0 1.8-.7 1.8-1.5v-19.8c0-.8-.8-1.5-1.8-1.5h-19.6"
          stroke="#fff"
          strokeWidth=".142"
          strokeMiterlimit="10"
        />
      </g>
      <path
        d="M1142.5 313.4H756.3c-6.6 0-12-5.4-12-12v-27.3h410.2v27.3c0 6.6-5.4 12-12 12z"
        fill="url(#paint11_linear)"
      />
      <path
        d="M1151.4 274.1h-404c-4.6 0-8.4-3.7-8.4-8.4 0-4.6 3.7-8.4 8.4-8.4h404c4.6 0 8.4 3.7 8.4 8.4s-3.8 8.4-8.4 8.4z"
        fill="#EACDA3"
      />
      <path
        d="M875.4 117.9c-5.4 0-9.8-4.4-9.8-9.8V89h-42.5v19.1c0 5.4-4.4 9.8-9.8 9.8-5.1 0-9.3 4.2-9.3 9.3V240c0 9.6 7.8 17.3 17.3 17.3h46.2c9.6 0 17.3-7.8 17.3-17.3V127.3c0-5.2-4.2-9.4-9.4-9.4z"
        fill="url(#paint12_linear)"
      />
      <path
        d="M865.8 89h-42.9c-2.9 0-5.3-2.4-5.3-5.3s2.4-5.3 5.3-5.3h42.9c2.9 0 5.3 2.4 5.3 5.3s-2.4 5.3-5.3 5.3z"
        fill="#3A65AE"
      />
      <path
        opacity=".1"
        d="M875.4 117.9c-5.4 0-9.8-4.4-9.8-9.8V89h-16v19.1c0 5.4 4.4 9.8 9.8 9.8 5.1 0 9.3 4.2 9.3 9.3V240c0 9.6-7.8 17.3-17.3 17.3h16c9.6 0 17.3-7.8 17.3-17.3V127.3c.1-5.2-4.1-9.4-9.3-9.4z"
        fill="#D8D8D8"
      />
      <path
        d="M803.9 212.1h45.5c3.3 0 6-2.7 6-6v-44.6c0-3.3-2.7-6-6-6h-45.5v56.6z"
        fill="#3D3D3B"
      />
      <path
        d="M804.8 209.3h42.7c2.2 0 3.9-1.5 3.9-3.4v-44.1c0-1.9-1.8-3.4-3.9-3.4h-43.6"
        stroke="#fff"
        strokeWidth=".316"
        strokeMiterlimit="10"
      />
      <path
        opacity=".1"
        d="M866.7 78.4H859c2.4 0 4.4 2 4.4 4.4v1.8c0 2.4-2 4.4-4.4 4.4h7.7c2.4 0 4.4-2 4.4-4.4v-1.8c0-2.4-2-4.4-4.4-4.4z"
        fill="#D8D8D8"
      />
      <path
        d="M936.6 193.9c-2.4 0-4.4-2-4.4-4.4v-8.6H913v8.6c0 2.4-2 4.4-4.4 4.4-2.3 0-4.2 1.9-4.2 4.2v50.7c0 4.3 3.5 7.8 7.8 7.8H933c4.3 0 7.8-3.5 7.8-7.8v-50.7c0-2.4-1.9-4.2-4.2-4.2z"
        fill="url(#paint13_linear)"
      />
      <path
        opacity=".1"
        d="M936.6 193.9c-2.4 0-4.4-2-4.4-4.4v-8.6H925v8.6c0 2.4 2 4.4 4.4 4.4 2.3 0 4.2 1.9 4.2 4.2v50.7c0 4.3-3.5 7.8-7.8 7.8h7.2c4.3 0 7.8-3.5 7.8-7.8v-50.7c0-2.4-1.9-4.2-4.2-4.2z"
        fill="#D8D8D8"
      />
      <path
        d="M923.8 177.2h-2.6c-1.5 0-2.8-1.3-2.8-2.8V159c0-2.2 1.8-4.1 4.1-4.1 2.2 0 4.1 1.8 4.1 4.1v15.4c.1 1.5-1.2 2.8-2.8 2.8z"
        fill="#3A65AE"
      />
      <path
        opacity=".1"
        d="M923.1 154.9c-.4 0-.8.1-1.2.2 1.7.7 2.9 2.7 2.9 5.1v14.3c0 1.4-.9 2.6-2 2.6h2.4c1.1 0 2-1.2 2-2.6v-14.3c0-2.9-1.8-5.3-4.1-5.3z"
        fill="#D8D8D8"
      />
      <path d="M930.9 172.9h-16.8v2.7h16.8v-2.7z" fill="#3D3D3B" />
      <path
        opacity=".17"
        d="M922.6 247.1c-1.4 0-2.6-5.1-2.6-6.8v-58.4c0-1.7 1.2-3.1 2.6-3.1 1.4 0 2.6 1.4 2.6 3.1v58.4c0 1.7-1.2 6.8-2.6 6.8z"
        fill="#C95F1A"
      />
      <path
        d="M932.6 181.6h-20.1c-1.8 0-3.3-1.5-3.3-3.3 0-1.8 1.5-3.3 3.3-3.3h20.1c1.8 0 3.3 1.5 3.3 3.3 0 1.8-1.5 3.3-3.3 3.3z"
        fill="#3A65AE"
      />
      <path
        d="M904.3 234.7h17.2c3.3 0 6-2.7 6-6v-13.5c0-3.3-2.7-6-6-6h-17.2v25.5z"
        fill="#3D3D3B"
      />
      <path
        d="M904.7 233.4h19.2c1 0 1.8-.7 1.8-1.5v-19.8c0-.8-.8-1.5-1.8-1.5h-19.6"
        stroke="#fff"
        strokeWidth=".142"
        strokeMiterlimit="10"
      />
      <path
        d="M1133.4 239.8l-33.9-72.7v-42.4h-28.7v42.4l-33.9 72.7c-3.5 7.6.9 17.1 8 17.1h80.5c7.1.1 11.5-9.5 8-17.1z"
        fill="#F5F5F5"
      />
      <path
        d="M1085.4 204.4h-26.8l-15.8 33.7c-3.2 6.8.8 15.2 7.2 15.2h70.7c6.4 0 10.4-8.5 7.2-15.2l-15.8-33.7h-26.7z"
        fill="url(#paint14_linear)"
      />
      <path
        d="M1085.4 209.9c14.8 0 26.8-2.462 26.8-5.5s-12-5.5-26.8-5.5-26.8 2.462-26.8 5.5 12 5.5 26.8 5.5z"
        fill="#81BEAF"
      />
      <path
        opacity=".27"
        d="M1134.2 239.8l-33.9-72.7v-42.4H1089v42.4l33.9 72.7c3.5 7.6-.9 17.1-8 17.1h11.4c7 .1 11.4-9.5 7.9-17.1z"
        fill="#D8D8D8"
      />
      <path
        d="M1102.2 125.4h-34c-2.7 0-4.9-2.2-4.9-4.9v-.9c0-2.7 2.2-4.9 4.9-4.9h34c2.7 0 4.9 2.2 4.9 4.9v.9c0 2.7-2.2 4.9-4.9 4.9z"
        fill="#F5F5F5"
      />
      <path
        opacity=".27"
        d="M1041.7 247l.9.4c2.6 1.2 5.7.2 6.9-2.4l20.5-42.4c1.2-2.6.2-5.7-2.4-6.9l-.9-.4c-2.6-1.2-5.7-.2-6.9 2.4l-20.5 42.4c-1.3 2.5-.2 5.6 2.4 6.9z"
        fill="#D8D8D8"
      />
      <path
        d="M1099.1 110c-.4 3-1.5 6-3.6 8.8-5.4 7.3-14.6 9.6-20.6 5.2-.6-.5-1.2-1-1.7-1.6l1.4 7.9c.3 1.9 1.7 3.2 3.3 3.2h14.3c1.6 0 2.9-1.3 3.3-3.2l3.4-18.8c.3-.5.3-1.1.2-1.5z"
        fill="#9A5C39"
      />
      <path
        d="M1075 124c6 4.4 15.2 2.1 20.6-5.2 2-2.7 3.2-5.8 3.6-8.8-.1-.9-.5-1.8-1-2.4-2.1 2.3-7.1 3.9-12.9 3.9-5.8 0-10.8-1.6-12.9-3.9-.8 1-1.2 2.4-1 3.9l2 10.9c.4.6.9 1.1 1.6 1.6z"
        fill="#C67B56"
      />
      <path
        d="M1085.1 111.5c5.8 0 10.9-1.6 12.9-3.9-.6-.7-1.4-1.1-2.3-1.1H1074.4c-.9 0-1.7.4-2.3 1.1 2.2 2.3 7.2 3.9 13 3.9z"
        fill="#DC9F58"
      />
      <path
        d="M1076 115.3a.801.801 0 000-1.6.801.801 0 000 1.6zM1081.5 124.4a1.5 1.5 0 10.001-2.999 1.5 1.5 0 00-.001 2.999zM1089.8 115.3a.601.601 0 000-1.2.601.601 0 000 1.2zM1092.6 119.8a.601.601 0 000-1.2.601.601 0 000 1.2zM1075.9 119.8a.601.601 0 000-1.2.601.601 0 000 1.2zM1081.5 117.2c.39 0 .7-.313.7-.7 0-.387-.31-.7-.7-.7-.39 0-.7.313-.7.7 0 .387.31.7.7.7zM1087.4 120.1c.5 0 .9-.403.9-.9s-.4-.9-.9-.9-.9.403-.9.9.4.9.9.9zM1095.5 114.1c.55 0 1-.448 1-1s-.45-1-1-1-1 .448-1 1 .45 1 1 1z"
        fill="#9A5C39"
      />
      <path
        d="M1092 129.4c.5 0 .9-.403.9-.9s-.4-.9-.9-.9-.9.403-.9.9.4.9.9.9zM1086.2 131c.88 0 1.6-.716 1.6-1.6 0-.884-.72-1.6-1.6-1.6-.88 0-1.6.716-1.6 1.6 0 .884.72 1.6 1.6 1.6z"
        fill="#C67B56"
      />
      <path
        opacity=".27"
        d="M1102.2 125.5h-34c-2.7 0-4.9-2.2-4.9-4.9v-.9c0-2.7 2.2-4.9 4.9-4.9h34c2.7 0 4.9 2.2 4.9 4.9v.9c0 2.6-2.2 4.9-4.9 4.9z"
        fill="#D8D8D8"
      />
      <g>
        <path
          d="M1149.5 554.4H50.5c-6.6 0-12-5.4-12-12v-35h1123v35c0 6.6-5.4 12-12 12z"
          fill="url(#paint15_linear)"
        />
        <path
          d="M1166 507.4H34c-5.5 0-10-4.5-10-10s4.5-10 10-10h1132c5.5 0 10 4.5 10 10s-4.5 10-10 10z"
          fill="#EACDA3"
        />
      </g>
      <g>
        <path
          d="M1002.3 374.4h-126c-4.8 0-8.6 1.9-8.6 4.3v24.1c0 2.4 3.9 4.3 8.6 4.3h126"
          stroke="url(#paint16_linear)"
          strokeWidth="6.51"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M997.3 404.2H875.9c-4.1 0-7.4-3.3-7.4-7.4v-12c0-4.1 3.3-7.4 7.4-7.4h121.4v26.8z"
          fill="#fff"
        />
        <path
          d="M871.1 382.4h122.1M871.1 386.8h122.1M871.1 391.1h122.1M871.1 395.5h122.1M871.1 399.9h122.1"
          stroke="#CDCCCC"
          strokeWidth="2.17"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <g>
        <path
          d="M854.8 412.2h126c4.8 0 8.6 1.9 8.6 4.3v24.1c0 2.4-3.9 4.3-8.6 4.3h-126"
          stroke="#066938"
          strokeWidth="6.51"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M859.8 442h121.4c4.1 0 7.4-3.3 7.4-7.4v-12c0-4.1-3.3-7.4-7.4-7.4H859.8V442z"
          fill="#fff"
        />
        <path
          d="M986 420.2H863.9M986 424.6H863.9M986 428.9H863.9M986 433.3H863.9M986 437.6H863.9"
          stroke="#CDCCCC"
          strokeWidth="2.17"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <g>
        <path
          d="M883.4 458.7h122"
          stroke="#CDCCCC"
          strokeWidth="2.17"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M1014.6 450.7h-126c-4.8 0-8.6 1.9-8.6 4.3v24.1c0 2.4 3.9 4.3 8.6 4.3h126"
          stroke="url(#paint17_linear)"
          strokeWidth="6.51"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M1009.5 480.5H888.1c-4.1 0-7.4-3.3-7.4-7.4v-12c0-4.1 3.3-7.4 7.4-7.4h121.4v26.8z"
          fill="#fff"
        />
        <path
          d="M883.4 463.1h122M883.4 467.4h122M883.4 471.8h122M883.4 476.2h122"
          stroke="#CDCCCC"
          strokeWidth="2.17"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <g>
        <path
          d="M356.7 426.7c-2.4 0-4.3-1.9-4.3-4.3v-8.3H334v8.3c0 2.4-1.9 4.3-4.3 4.3-2.2 0-4 1.8-4 4v48.9c0 4.2 3.4 7.5 7.5 7.5h20.1c4.2 0 7.5-3.4 7.5-7.5v-48.9c0-2.1-1.9-4-4.1-4z"
          fill="url(#paint18_linear)"
        />
        <path
          opacity=".1"
          d="M356.7 426.7c-2.4 0-4.3-1.9-4.3-4.3v-8.3h-6.9v8.3c0 2.4 1.9 4.3 4.3 4.3 2.2 0 4 1.8 4 4v48.9c0 4.2-3.4 7.5-7.5 7.5h6.9c4.2 0 7.5-3.4 7.5-7.5v-48.9c.1-2.1-1.8-4-4-4z"
          fill="#D8D8D8"
        />
        <path
          d="M325.7 467.6H342c3.3 0 6-2.7 6-6V449c0-3.3-2.7-6-6-6h-16.4v24.6h.1z"
          fill="#3D3D3B"
        />
        <path
          d="M326 466.4h18.5c.9 0 1.7-.7 1.7-1.5v-19.2c0-.8-.8-1.5-1.7-1.5h-18.9"
          stroke="#fff"
          strokeWidth=".137"
          strokeMiterlimit="10"
        />
        <g>
          <path
            d="M352 416h-17.6c-1.4 0-2.5-1.1-2.5-2.5v-3.1c0-1.4 1.1-2.5 2.5-2.5H352c1.4 0 2.5 1.1 2.5 2.5v3.1c0 1.4-1.1 2.5-2.5 2.5z"
            fill="#3A65AE"
          />
          <path
            d="M334.3 409.1v5.8M336.6 409.1v5.8M338.8 409.1v5.8M340.9 409.1v5.8M343.2 409.1v5.8M345.4 409.1v5.8M347.3 409.1v5.8M349.6 409.1v5.8M351.6 409.1v5.8M334.3 409.1v5.8M336.6 409.1v5.8"
            stroke="#1E1E1C"
            strokeWidth=".457"
            strokeMiterlimit="10"
          />
        </g>
        <path
          opacity=".1"
          d="M353.2 407.9h-7.8c1.8 0 3.3 1.5 3.3 3.4v1.4c0 1.9-1.5 3.4-3.3 3.4h7.8c.7 0 1.3-.6 1.3-1.3v-5.5c0-.8-.6-1.4-1.3-1.4z"
          fill="#D8D8D8"
        />
      </g>
      <g>
        <path
          d="M987.8 144.6c-.7-.1-1.4-.4-2-.7-.6-.3-1.2-.8-1.7-1.3s-.9-1.1-1.1-1.7c-.3-.6-.4-1.3-.5-2 .1.7.3 1.3.6 1.9.3.6.7 1.2 1.2 1.6.5.5 1 .8 1.6 1.1.6.3 1.3.5 1.9.5v.6z"
          fill="#056734"
        />
        <path d="M987.7 157.2l-5.2-49.7 8.7 49.2-3.5.5z" fill="#056734" />
        <path
          d="M969.5 128c4.7-1.7 9.6-.3 10.9 3.2 1.3 3.5-1.5 7.8-6.2 9.5-4.7 1.7-12.8-2.5-12.8-2.5s3.4-8.4 8.1-10.2z"
          fill="url(#paint19_linear)"
          fillOpacity=".1"
        />
        <path d="M985.5 130.1l-22.7 7.5 22.3-8.6.4 1.1z" fill="#056734" />
        <path
          d="M978.3 132.1l-4.9-2.9 5 2.8-.1.1zM967.9 135.8l-2.1-1.2 2.2 1.1-.1.1zM971.1 134.7l-3.4-2 3.5 1.9-.1.1zM974.3 133.5l-4.1-2.4 4.2 2.3-.1.1zM978.3 132.2l-2.1 5.3 2-5.3h.1zM967.9 136l-.9 2.2.8-2.3.1.1zM971.1 134.8l-1.5 3.7 1.4-3.7h.1zM974.3 133.6l-1.8 4.5 1.7-4.5h.1z"
          fill="#056734"
        />
        <g>
          <path
            d="M980.8 140.5c2.6-2.3 3.4-5.7 1.7-7.6-1.7-1.9-5.2-1.7-7.8.6-2.6 2.3-2.8 8.6-2.8 8.6s6.3.7 8.9-1.6z"
            fill="url(#paint20_linear)"
          />
          <path d="M985.5 130.8l-12.7 10.5 12.2-11.1.5.6z" fill="#056734" />
          <path
            d="M981.4 133.8v4l-.1-4h.1zM975.7 138.8l-.1 1.7v-1.7h.1zM977.5 137.3l-.1 2.8v-2.8h.1zM981.3 133.9l-3.9-.6 3.9.5v.1zM975.5 138.9l-1.7-.3 1.7.2v.1zM977.3 137.3l-2.8-.4 2.8.3v.1zM979.1 135.8l-3.3-.5 3.3.4v.1z"
            fill="#056734"
          />
        </g>
        <g>
          <path
            d="M984.6 140.9c-1 .3-3.4.2-3.8-.8-.3-1 1.6-2.5 2.6-2.8 1-.3 2.1.2 2.4 1.2.3 1-.2 2.1-1.2 2.4z"
            fill="#DF6171"
          />
          <path
            d="M981.4 144.1c-.6-.9-1.3-3.2-.4-3.8.9-.6 2.9.7 3.5 1.6.6.9.4 2.1-.4 2.7-.9.6-2.1.4-2.7-.5z"
            fill="#DF6171"
          />
          <path
            d="M977.3 142.1c.6-.9 2.6-2.2 3.5-1.6.9.6.2 3-.4 3.8-.6.9-1.8 1-2.7.4-.9-.6-1-1.8-.4-2.6z"
            fill="#DF6171"
          />
          <path
            d="M978.1 137.5c1 .3 2.9 1.8 2.6 2.8-.3 1-2.7 1.1-3.8.8-1.1-.3-1.6-1.4-1.2-2.4.4-1 1.3-1.5 2.4-1.2z"
            fill="#DF6171"
          />
          <path
            d="M982.6 136.8c0 1.1-.9 3.3-1.9 3.3-1.1 0-1.9-2.3-1.9-3.3 0-1.1.9-1.9 1.9-1.9 1 0 1.9.9 1.9 1.9z"
            fill="#DF6171"
          />
          <g>
            <path
              d="M980.7 140.8a.5.5 0 100-1 .5.5 0 000 1zM980.7 139.1a.3.3 0 100-.6.3.3 0 000 .6zM981.7 139.5a.3.3 0 100-.6.3.3 0 000 .6z"
              fill="#EFEBE4"
            />
            <path
              d="M981.1 139.3a.2.2 0 00.2-.2.2.2 0 00-.2-.2.2.2 0 00-.2.2c0 .11.09.2.2.2zM981.8 140a.2.2 0 100-.4.2.2 0 000 .4zM980.7 142a.3.3 0 100-.6.3.3 0 000 .6zM982.2 140.6a.3.3 0 100-.6.3.3 0 000 .6zM981.7 141.6a.3.3 0 100-.6.3.3 0 000 .6zM979.7 139.5a.3.3 0 100-.6.3.3 0 000 .6zM979.2 140.6a.3.3 0 100-.6.3.3 0 000 .6zM979.7 141.6a.3.3 0 100-.6.3.3 0 000 .6zM981.8 141a.2.2 0 100-.4.2.2 0 000 .4z"
              fill="#EFEBE4"
            />
            <path
              d="M981.1 141.6a.2.2 0 00.2-.2.2.2 0 00-.2-.2.2.2 0 00-.2.2c0 .11.09.2.2.2zM980.3 139.3a.2.2 0 100-.4.2.2 0 000 .4zM979.6 140a.2.2 0 00.2-.2.2.2 0 00-.2-.2.2.2 0 00-.2.2c0 .11.09.2.2.2zM979.6 141a.2.2 0 00.2-.2.2.2 0 00-.2-.2.2.2 0 00-.2.2c0 .11.09.2.2.2zM980.3 141.6a.2.2 0 100-.4.2.2 0 000 .4z"
              fill="#EFEBE4"
            />
          </g>
        </g>
        <g>
          <path
            d="M1005.9 115c2-.1 4.1-.3 6.1-.9 1-.3 1.9-.7 2.8-1.2.9-.5 1.6-1.3 2-2.3-.3 1-1.1 1.8-1.9 2.4-.9.6-1.8 1.1-2.8 1.4-2 .7-4 1.1-6.1 1.3l-.1-.7z"
            fill="#056734"
          />
          <path
            d="M1013.3 134.7c-3.8 2.2-8.2 1.7-9.8-1.1-1.6-2.8.1-6.9 3.9-9.1 3.8-2.2 11.4.2 11.4.2s-1.7 7.8-5.5 10z"
            fill="url(#paint21_linear)"
          />
          <path d="M999.3 135.4l18.3-9.9-17.8 10.8-.5-.9z" fill="#056734" />
          <path
            d="M1005.1 132.6l4.7 1.7-4.7-1.6v-.1zM1013.5 127.8l1.9.7-2-.6.1-.1zM1010.9 129.3l3.3 1.2-3.4-1.1.1-.1zM1008.3 130.8l4 1.4-4-1.3v-.1zM1005.1 132.5l1-4.9-.9 4.9h-.1zM1013.5 127.7l.4-2.1-.3 2.1h-.1zM1010.9 129.2l.7-3.5-.6 3.5h-.1zM1008.3 130.7l.8-4.2-.7 4.2h-.1zM987.8 167.5l26.2-79.6-21.3 81.1-4.9-1.5z"
            fill="#056734"
          />
          <g>
            <path
              d="M1023 107.6c-3.9 3.1-9 3.2-11.3.3-2.3-2.9-1-7.8 3-10.9 4-3.1 12.9-1.6 12.9-1.6s-.7 9.1-4.6 12.2z"
              fill="url(#paint22_linear)"
            />
            <path d="M1007.1 110.6l19.2-14.2-18.5 15.2-.7-1z" fill="#056734" />
            <path
              d="M1013.3 106.5l5.6 1.2-5.6-1.1v-.1zM1022 99.7l2.4.5-2.4-.4v-.1zM1019.3 101.8l3.9.8-3.9-.7v-.1zM1016.6 103.9l4.8 1-4.8-.9v-.1zM1013.3 106.4l.3-5.7-.2 5.7h-.1zM1022 99.6l.2-2.4-.1 2.4h-.1zM1019.3 101.7l.3-4-.2 4h-.1zM1016.6 103.8l.3-4.9-.2 4.9h-.1z"
              fill="#056734"
            />
          </g>
          <g>
            <path
              d="M1022.8 112.9c-1.4.5-4.8.3-5.3-1.1-.5-1.4 2.2-3.5 3.6-4s2.9.3 3.4 1.7c.5 1.5-.3 3-1.7 3.4z"
              fill="#F39FA6"
            />
            <path
              d="M1018.2 117.5c-.9-1.2-1.8-4.5-.6-5.4 1.2-.9 4.1 1 4.9 2.2.9 1.2.6 2.9-.6 3.8-1.1.9-2.8.6-3.7-.6z"
              fill="#F39FA6"
            />
            <path
              d="M1012.5 114.6c.9-1.2 3.7-3.1 4.9-2.2 1.2.9.3 4.2-.6 5.4-.9 1.2-2.6 1.5-3.8.6-1.1-.9-1.4-2.6-.5-3.8z"
              fill="#F39FA6"
            />
            <path
              d="M1013.5 108.2c1.4.5 4.1 2.6 3.6 4-.5 1.4-3.9 1.6-5.3 1.1s-2.2-2-1.7-3.4c.5-1.4 2-2.1 3.4-1.7z"
              fill="#F39FA6"
            />
            <path
              d="M1019.9 107.2c0 1.5-1.2 4.7-2.7 4.7-1.5 0-2.7-3.2-2.7-4.7s1.2-2.7 2.7-2.7c1.5 0 2.7 1.2 2.7 2.7z"
              fill="#F39FA6"
            />
            <g>
              <path
                d="M1017.2 112.8a.801.801 0 000-1.6.801.801 0 000 1.6zM1017.2 110.4a.4.4 0 10-.001-.801.4.4 0 00.001.801zM1018.6 110.9a.4.4 0 10-.001-.801.4.4 0 00.001.801zM1017.8 110.8c.17 0 .3-.134.3-.3 0-.166-.13-.3-.3-.3-.17 0-.3.134-.3.3 0 .166.13.3.3.3zM1018.8 111.7c.17 0 .3-.134.3-.3 0-.166-.13-.3-.3-.3-.17 0-.3.134-.3.3 0 .166.13.3.3.3zM1017.2 114.5a.4.4 0 10-.001-.801.4.4 0 00.001.801zM1019.3 112.4a.4.4 0 10-.001-.801.4.4 0 00.001.801zM1018.6 114a.4.4 0 10-.001-.801.4.4 0 00.001.801zM1015.8 111a.4.4 0 10-.001-.801.4.4 0 00.001.801zM1015.2 112.4a.4.4 0 10-.001-.801.4.4 0 00.001.801zM1015.8 113.9a.4.4 0 10-.001-.801.4.4 0 00.001.801zM1018.8 113.1c.17 0 .3-.134.3-.3 0-.166-.13-.3-.3-.3-.17 0-.3.134-.3.3 0 .166.13.3.3.3z"
                fill="#EFEBE4"
              />
              <path
                d="M1017.9 113.9c.17 0 .3-.134.3-.3 0-.166-.13-.3-.3-.3-.17 0-.3.134-.3.3 0 .166.13.3.3.3zM1016.7 110.8c.17 0 .3-.134.3-.3 0-.166-.13-.3-.3-.3-.17 0-.3.134-.3.3 0 .166.13.3.3.3zM1015.7 111.7c.17 0 .3-.134.3-.3 0-.166-.13-.3-.3-.3-.17 0-.3.134-.3.3 0 .166.13.3.3.3zM1015.7 113.1c.17 0 .3-.134.3-.3 0-.166-.13-.3-.3-.3-.17 0-.3.134-.3.3 0 .166.13.3.3.3zM1016.6 113.9c.17 0 .3-.134.3-.3 0-.166-.13-.3-.3-.3-.17 0-.3.134-.3.3 0 .166.13.3.3.3z"
                fill="#EFEBE4"
              />
            </g>
          </g>
          <g>
            <path
              d="M1018.9 92.8c-1.4.5-4.8.3-5.3-1.1-.5-1.4 2.2-3.5 3.6-4s2.9.3 3.4 1.7c.5 1.5-.3 3-1.7 3.4z"
              fill="#DF6171"
            />
            <path
              d="M1014.4 97.4c-.9-1.2-1.8-4.5-.6-5.4 1.2-.9 4.1 1 4.9 2.2.9 1.2.6 2.9-.6 3.8-1.2.9-2.8.6-3.7-.6z"
              fill="#DF6171"
            />
            <path
              d="M1008.7 94.5c.9-1.2 3.7-3.1 4.9-2.2 1.2.9.3 4.2-.6 5.4-.9 1.2-2.6 1.5-3.8.6-1.2-.9-1.4-2.6-.5-3.8z"
              fill="#DF6171"
            />
            <path
              d="M1009.7 88.1c1.4.5 4.1 2.6 3.6 4-.5 1.4-3.9 1.6-5.3 1.1s-2.2-2-1.7-3.4c.4-1.4 1.9-2.1 3.4-1.7z"
              fill="#DF6171"
            />
            <path
              d="M1016 87.1c0 1.5-1.2 4.7-2.7 4.7-1.5 0-2.7-3.2-2.7-4.7s1.2-2.7 2.7-2.7c1.5 0 2.7 1.2 2.7 2.7z"
              fill="#DF6171"
            />
            <g>
              <path
                d="M1013.4 92.7a.801.801 0 000-1.6.801.801 0 000 1.6zM1013.4 90.3a.4.4 0 10-.001-.801.4.4 0 00.001.801zM1014.7 90.8a.4.4 0 10-.001-.801.4.4 0 00.001.801z"
                fill="#EFEBE4"
              />
              <path
                d="M1014 90.7c.17 0 .3-.134.3-.3 0-.166-.13-.3-.3-.3-.17 0-.3.134-.3.3 0 .166.13.3.3.3zM1015 91.6c.17 0 .3-.134.3-.3 0-.166-.13-.3-.3-.3-.17 0-.3.134-.3.3 0 .166.13.3.3.3zM1013.4 94.4a.4.4 0 10-.001-.801.4.4 0 00.001.801zM1015.5 92.3a.4.4 0 10-.001-.801.4.4 0 00.001.801zM1014.8 93.9a.4.4 0 10-.001-.801.4.4 0 00.001.801zM1012 90.9a.4.4 0 10-.001-.801.4.4 0 00.001.801zM1011.3 92.3a.4.4 0 10-.001-.801.4.4 0 00.001.801zM1012 93.8a.4.4 0 10-.001-.801.4.4 0 00.001.801zM1014.9 93c.17 0 .3-.134.3-.3 0-.166-.13-.3-.3-.3-.17 0-.3.134-.3.3 0 .166.13.3.3.3zM1014 93.8c.17 0 .3-.134.3-.3 0-.166-.13-.3-.3-.3-.17 0-.3.134-.3.3 0 .166.13.3.3.3zM1012.8 90.7c.17 0 .3-.134.3-.3 0-.166-.13-.3-.3-.3-.17 0-.3.134-.3.3 0 .166.13.3.3.3zM1011.8 91.6c.17 0 .3-.134.3-.3 0-.166-.13-.3-.3-.3-.17 0-.3.134-.3.3 0 .166.13.3.3.3z"
                fill="#EFEBE4"
              />
              <path
                d="M1011.9 93c.17 0 .3-.134.3-.3 0-.166-.13-.3-.3-.3-.17 0-.3.134-.3.3 0 .166.13.3.3.3zM1012.8 93.8c.17 0 .3-.134.3-.3 0-.166-.13-.3-.3-.3-.17 0-.3.134-.3.3 0 .166.13.3.3.3z"
                fill="#EFEBE4"
              />
            </g>
          </g>
        </g>
        <g>
          <path d="M990.3 160.5l2.3-75.3 2.4 75.3h-4.7z" fill="#056734" />
          <path
            d="M1002.1 97.2c-1.6 3.5-5.1 5.4-7.7 4.2-2.6-1.2-3.5-5-1.9-8.6 1.6-3.5 8.3-5.7 8.3-5.7s2.9 6.5 1.3 10.1z"
            fill="url(#paint23_linear)"
          />
          <path d="M992.3 104.9l8.2-16.6-7.4 17-.8-.4z" fill="#056734" />
          <path
            d="M995.1 99.8l4.3-1.1-4.3 1.2v-.1zM998.7 92.1l1.8-.5-1.8.6v-.1zM997.6 94.5l3-.8-3 .9v-.1zM996.5 96.9l3.6-1-3.6 1.1v-.1zM995 99.8l-1.8-4.1 1.9 4-.1.1zM998.6 92l-.7-1.7.8 1.6-.1.1zM997.5 94.4l-1.2-2.9 1.3 2.8-.1.1zM996.4 96.8l-1.5-3.4 1.6 3.4h-.1z"
            fill="#056734"
          />
          <g>
            <path
              d="M992.6 110.4c-1.1-.2-2.1-.7-3-1.4-.9-.7-1.6-1.5-2.2-2.4-.6-.9-1-1.9-1.3-2.9-.1-.5-.2-1-.4-1.5l-.2-1.6.3 1.5c.1.5.3 1 .4 1.5.4 1 .8 1.9 1.4 2.8.6.8 1.3 1.6 2.2 2.1.8.6 1.8 1 2.7 1.1l.1.8z"
              fill="#056734"
            />
          </g>
          <g>
            <path
              d="M991.3 101.3c-1.4.5-4.8.3-5.3-1.1-.5-1.4 2.2-3.5 3.6-4s2.9.3 3.4 1.7-.3 3-1.7 3.4z"
              fill="#F39FA6"
            />
            <path
              d="M986.7 105.9c-.9-1.2-1.8-4.5-.6-5.4 1.2-.9 4.1 1 4.9 2.2.9 1.2.6 2.9-.6 3.8-1.2.9-2.8.6-3.7-.6z"
              fill="#F39FA6"
            />
            <path
              d="M981 102.9c.9-1.2 3.7-3.1 4.9-2.2 1.2.9.3 4.2-.6 5.4-.9 1.2-2.6 1.5-3.8.6-1.1-.9-1.4-2.5-.5-3.8z"
              fill="#F39FA6"
            />
            <path
              d="M982 96.6c1.4.5 4.1 2.6 3.6 4-.5 1.4-3.9 1.6-5.3 1.1s-2.2-2-1.7-3.4c.5-1.4 2-2.2 3.4-1.7z"
              fill="#F39FA6"
            />
            <path
              d="M988.4 95.6c0 1.5-1.2 4.7-2.7 4.7-1.5 0-2.7-3.2-2.7-4.7s1.2-2.7 2.7-2.7c1.5 0 2.7 1.2 2.7 2.7z"
              fill="#F39FA6"
            />
            <g>
              <path
                d="M985.8 101.2a.8.8 0 100-1.6.8.8 0 000 1.6zM985.8 98.8a.4.4 0 100-.8.4.4 0 000 .8zM987.1 99.3a.4.4 0 100-.8.4.4 0 000 .8z"
                fill="#EFEBE4"
              />
              <path
                d="M986.3 99.1a.3.3 0 100-.6.3.3 0 000 .6zM987.3 100.1a.3.3 0 100-.6.3.3 0 000 .6zM985.8 102.9a.4.4 0 100-.8.4.4 0 000 .8zM987.8 100.8a.4.4 0 100-.8.4.4 0 000 .8zM987.1 102.3a.4.4 0 100-.8.4.4 0 000 .8zM984.3 99.3a.4.4 0 100-.8.4.4 0 000 .8zM983.7 100.8a.4.4 0 100-.8.4.4 0 000 .8zM984.3 102.3a.4.4 0 100-.8.4.4 0 000 .8zM987.3 101.5a.3.3 0 100-.6.3.3 0 000 .6z"
                fill="#EFEBE4"
              />
              <path
                d="M986.4 102.3a.3.3 0 100-.6.3.3 0 000 .6zM985.2 99.1a.3.3 0 100-.6.3.3 0 000 .6zM984.2 100.1a.3.3 0 100-.6.3.3 0 000 .6zM984.2 101.5a.3.3 0 100-.6.3.3 0 000 .6zM985.1 102.3a.3.3 0 100-.6.3.3 0 000 .6z"
                fill="#EFEBE4"
              />
            </g>
          </g>
          <g>
            <path
              d="M1008.1 129.5c-3.3 3.8-8.3 4.8-11.1 2.3-2.8-2.5-2.4-7.5.9-11.3 3.3-3.8 12.4-3.9 12.4-3.9s1.2 9.2-2.2 12.9z"
              fill="url(#paint24_linear)"
            />
            <path d="M993.1 135.4l16.3-17.5-15.4 18.3-.9-.8z" fill="#056734" />
            <path
              d="M998.4 130.2l5.7.1h-5.7v-.1zM1005.8 121.9l2.4.1-2.4.1v-.2zM1003.5 124.5l4 .1h-4v-.1zM1001.2 127l4.9.2h-4.9v-.2zM998.4 130.1l-.7-5.7.8 5.6-.1.1zM1005.7 121.8l-.2-2.4.4 2.4h-.2zM1003.4 124.4l-.4-4 .6 3.9-.2.1zM1001.2 126.9l-.6-4.8.7 4.8h-.1z"
              fill="#056734"
            />
          </g>
          <g>
            <path
              d="M983.8 114.9c4.2 2.8 5.9 7.5 3.8 10.7-2.1 3.1-7.1 3.4-11.3.6-4.2-2.8-5.5-11.8-5.5-11.8s8.8-2.3 13 .5z"
              fill="url(#paint25_linear)"
            />
            <path d="M991.6 129l-19.5-13.8 20.2 12.8-.7 1z" fill="#056734" />
            <path
              d="M985.8 124.4l-.6-5.7.7 5.7h-.1zM976.6 118.3l-.2-2.4.3 2.4h-.1zM979.4 120.2l-.4-4 .6 4h-.2zM982.3 122.1l-.6-4.8.7 4.8h-.1zM985.7 124.5l-5.6 1.5 5.5-1.7.1.2zM976.5 118.3l-2.4.6 2.3-.7.1.1zM979.3 120.2l-3.9 1 3.9-1.1v.1zM982.2 122.1l-4.7 1.3 4.6-1.4.1.1z"
              fill="#056734"
            />
          </g>
        </g>
        <g>
          <path
            d="M991.5 150.6v-.2c0-3.1-2.6-5.7-5.8-5.7-3.2 0-5.8 2.5-5.8 5.7v.2h11.6z"
            fill="#372C28"
          />
          <path
            d="M1008.7 152.9v-.4c0-5.3-4.4-9.6-9.8-9.6s-9.8 4.3-9.8 9.6v.4h19.6z"
            fill="#372C28"
          />
          <path
            d="M1018.4 151.7v-.3c0-4.5-3.5-8.1-7.9-8.1s-7.9 3.6-7.9 8.1v.3h15.8z"
            fill="#372C28"
          />
          <path
            d="M1018.1 257.4h-49c-4.5 0-8.3-3.7-8.3-8.3v-85.2h65.5v85.3c0 4.5-3.7 8.2-8.2 8.2z"
            fill="#E28F5C"
          />
          <path
            opacity=".27"
            d="M974.8 232.6h-3c-1.7 0-3.1-1.4-3.1-3.1v-37.7c0-1.7 1.4-3.1 3.1-3.1h3c1.7 0 3.1 1.4 3.1 3.1v37.7c0 1.7-1.4 3.1-3.1 3.1z"
            fill="#D8D8D8"
          />
          <g fill="#1E1E1C">
            <path d="M987.3 177.9h-23.2v.4h23.2v-.4zM987.3 193.7h-23.2v.4h23.2v-.4zM987.3 209.5h-23.2v.4h23.2v-.4zM987.3 225.4h-23.2v.4h23.2v-.4zM987.3 241.2h-23.2v.4h23.2v-.4zM972.8 183.5h-8.3v.4h8.3v-.4zM972.8 180.1h-8.3v.4h8.3v-.4zM972.8 186.9h-8.3v.4h8.3v-.4zM972.8 190.3h-8.3v.4h8.3v-.4zM972.8 199.4h-8.3v.4h8.3v-.4zM972.8 196h-8.3v.4h8.3v-.4zM972.8 202.8h-8.3v.4h8.3v-.4zM972.8 206.2h-8.3v.4h8.3v-.4zM972.8 215.8h-8.3v.4h8.3v-.4zM972.8 212.4h-8.3v.4h8.3v-.4zM972.8 219.2h-8.3v.4h8.3v-.4zM972.8 222.6h-8.3v.4h8.3v-.4zM972.8 231.6h-8.3v.4h8.3v-.4zM972.8 228.2h-8.3v.4h8.3v-.4zM972.8 235h-8.3v.4h8.3v-.4zM972.8 238.4h-8.3v.4h8.3v-.4z" />
          </g>
          <path
            d="M1026.3 153.8c0-5.3-4.2-9.6-9.4-9.6-2.5 0-4.7 1-6.4 2.6-1.1-.7-2.5-1.1-3.9-1.1-2.8 0-5.2 1.5-6.6 3.7-.9-.7-2-1.1-3.2-1.1-1.7 0-3.3.8-4.2 2.1-.9-1.3-2.4-2.1-4-2.1-1.1 0-2.2.4-3 1.1-1.3-2.2-3.6-3.7-6.2-3.7-1.3 0-2.6.4-3.7 1.1-1.6-1.6-3.7-2.6-6-2.6-4.9 0-8.8 4.3-8.8 9.6h65.4z"
            fill="#654B47"
          />
          <path d="M1026.3 163.9h-65.5v9.5h65.5v-9.5z" fill="#C6603F" />
          <path
            d="M1026.3 156.7c0-5.3-4.2-9.6-9.4-9.6-2.5 0-4.7 1-6.4 2.6-1.1-.7-2.5-1.1-3.9-1.1-2.8 0-5.2 1.5-6.6 3.7-.9-.7-2-1.1-3.2-1.1-1.7 0-3.3.8-4.2 2.1-.9-1.3-2.4-2.1-4-2.1-1.1 0-2.2.4-3 1.1-1.3-2.2-3.6-3.7-6.2-3.7-1.3 0-2.6.4-3.7 1.1-1.6-1.6-3.7-2.6-6-2.6-4.9 0-8.8 4.3-8.8 9.6h65.4z"
            fill="#1E1E1C"
          />
          <path
            d="M1027.1 163.9H960c-2.9 0-5.2-2.3-5.2-5.2v-3.3c0-.9.7-1.6 1.6-1.6h74.3c.9 0 1.6.7 1.6 1.6v3.3c0 2.8-2.3 5.2-5.2 5.2z"
            fill="#E28F5C"
          />
          <path
            opacity=".27"
            d="M1015.2 163.9v89.5c0 2.2-2 4-4.5 4h11.1c2.5 0 4.5-1.8 4.5-4v-89.5h-11.1z"
            fill="#D8D8D8"
          />
          <path
            d="M1022.5 257.4h-58.1c-2 0-3.6-1.6-3.6-3.6v-2h65.5v1.8c0 2.1-1.7 3.8-3.8 3.8z"
            fill="#C6603F"
          />
        </g>
      </g>
      <g>
        <path
          d="M188.7 443.8V438h-28.5v5.8c0 7.3-5.9 13.3-13.3 13.3-12.9 0-23.3 10.4-23.3 23.3v7.6h101.7v-7.6c0-12.9-10.4-23.3-23.3-23.3-7.3 0-13.3-6-13.3-13.3z"
          fill="#F4F3F4"
        />
        <path
          d="M310.3 438H38.6c-2.1 0-3.9-1.7-3.9-3.9V271.3c0-2.1 1.7-3.9 3.9-3.9h271.7c2.1 0 3.9 1.7 3.9 3.9v162.9c0 2.1-1.8 3.8-3.9 3.8z"
          fill="#808181"
        />
        <path
          d="M174.5 427a5.5 5.5 0 100-11 5.5 5.5 0 100 11zM303.6 404.9H45.3c-2.1 0-3.9-1.7-3.9-3.9V276.9c0-2.1 1.7-3.9 3.9-3.9h258.3c2.1 0 3.9 1.7 3.9 3.9V401c-.1 2.2-1.8 3.9-3.9 3.9z"
          fill="#F4F3F4"
        />
        <path
          d="M233 438c-.6 55.7 60.1-12.8 60.7 36.8"
          stroke="#4E4D4D"
          strokeWidth="1.654"
          strokeMiterlimit="10"
        />
        <path
          d="M304.6 488.5h-26.4c-1.3 0-2.3-1.1-2.3-2.3V481c0-3.7 3-6.7 6.7-6.7h17.7c3.7 0 6.7 3 6.7 6.7v5.2c-.1 1.3-1.1 2.3-2.4 2.3z"
          fill="#1B1B1B"
        />
      </g>
      <g>
        <path
          d="M609.2 381.9H585v74.6c0 .1-.5 6.7 3.1 11.1 2 2.4 4.8 3.7 8.4 3.9h.8c3.4 0 6.1-1 8.1-3.1 3.7-3.8 3.7-9.7 3.7-9.8v-76.7h.1zM610 381.9h-25.4c-2 0-3.7-1.7-3.7-3.7v-.7c0-2 1.7-3.7 3.7-3.7H610c2 0 3.7 1.7 3.7 3.7v.7c0 2.1-1.7 3.7-3.7 3.7z"
          fill="#F5F5F5"
        />
        <path
          d="M597.2 472.2c-6.7 0-12.2-5.5-12.2-12.2v-49.8h24.4V460c0 6.8-5.5 12.2-12.2 12.2z"
          fill="url(#paint26_linear)"
        />
        <path
          d="M597.3 412.4c6.683 0 12.1-.94 12.1-2.1 0-1.16-5.417-2.1-12.1-2.1s-12.1.94-12.1 2.1c0 1.16 5.417 2.1 12.1 2.1z"
          fill="url(#paint27_linear)"
        />
        <path
          opacity=".27"
          d="M605 381.9v77.4c0 .1 0 6.1-3.7 9.8-1.5 1.6-3.4 2.5-5.7 2.9.5.1 1.1.1 1.6.2h.8c3.4 0 6.1-1 8.1-3.1 3.7-3.8 3.7-9.8 3.7-9.8v-77.4H605z"
          fill="#D8D8D8"
        />
        <path
          opacity=".1"
          d="M590.5 449.5c-1.5 0-2.6-1.2-2.6-2.6v-29.1c0-1.5 1.2-2.6 2.6-2.6 1.5 0 2.6 1.2 2.6 2.6v29.1c0 1.4-1.2 2.6-2.6 2.6z"
          fill="#D8D8D8"
        />
        <g>
          <path
            d="M708.5 381.9h-24.2v74.6c0 .1-.5 6.7 3.1 11.1 2 2.4 4.8 3.7 8.4 3.9h.8c3.4 0 6.1-1 8.1-3.1 3.7-3.8 3.7-9.7 3.7-9.8v-76.7h.1zM709.2 381.9h-25.4c-2 0-3.7-1.7-3.7-3.7v-.7c0-2 1.7-3.7 3.7-3.7h25.4c2 0 3.7 1.7 3.7 3.7v.7c0 2.1-1.6 3.7-3.7 3.7z"
            fill="#F5F5F5"
          />
          <path
            d="M696.5 472.2c-6.7 0-12.2-5.5-12.2-12.2v-49.8h24.4V460c-.1 6.8-5.5 12.2-12.2 12.2z"
            fill="url(#paint28_linear)"
          />
          <path
            d="M696.5 412.4c6.683 0 12.1-.94 12.1-2.1 0-1.16-5.417-2.1-12.1-2.1s-12.1.94-12.1 2.1c0 1.16 5.417 2.1 12.1 2.1z"
            fill="url(#paint29_linear)"
          />
          <path
            opacity=".27"
            d="M704.2 381.9v77.4c0 .1 0 6.1-3.7 9.8-1.5 1.6-3.4 2.5-5.7 2.9.5.1 1.1.1 1.6.2h.8c3.4 0 6.1-1 8.1-3.1 3.7-3.8 3.7-9.8 3.7-9.8v-77.4h-4.8z"
            fill="#D8D8D8"
          />
          <path
            opacity=".28"
            d="M689.7 449.5c-1.5 0-2.6-1.2-2.6-2.6v-29.1c0-1.5 1.2-2.6 2.6-2.6 1.5 0 2.6 1.2 2.6 2.6v29.1c0 1.4-1.1 2.6-2.6 2.6z"
            fill="#D8D8D8"
          />
        </g>
        <g>
          <path
            d="M660.2 381.9H636v74.6c0 .1-.5 6.7 3.1 11.1 2 2.4 4.8 3.7 8.4 3.9h.8c3.4 0 6.1-1 8.1-3.1 3.7-3.8 3.7-9.7 3.7-9.8v-76.7h.1zM661 381.9h-25.4c-2 0-3.7-1.7-3.7-3.7v-.7c0-2 1.7-3.7 3.7-3.7H661c2 0 3.7 1.7 3.7 3.7v.7c-.1 2.1-1.7 3.7-3.7 3.7z"
            fill="#F5F5F5"
          />
          <path
            d="M648.2 472.2c-6.7 0-12.2-5.5-12.2-12.2v-49.8h24.4V460c0 6.8-5.5 12.2-12.2 12.2z"
            fill="url(#paint30_linear)"
          />
          <path
            d="M648.3 412.4c6.683 0 12.1-.94 12.1-2.1 0-1.16-5.417-2.1-12.1-2.1s-12.1.94-12.1 2.1c0 1.16 5.417 2.1 12.1 2.1z"
            fill="url(#paint31_linear)"
          />
          <path
            opacity=".27"
            d="M656 381.9v77.4c0 .1 0 6.1-3.7 9.8-1.5 1.6-3.4 2.5-5.7 2.9.5.1 1.1.1 1.6.2h.8c3.4 0 6.1-1 8.1-3.1 3.7-3.8 3.7-9.8 3.7-9.8v-77.4H656z"
            fill="#D8D8D8"
          />
          <path
            opacity=".1"
            d="M641.4 449.5c-1.5 0-2.6-1.2-2.6-2.6v-29.1c0-1.5 1.2-2.6 2.6-2.6 1.5 0 2.6 1.2 2.6 2.6v29.1c.1 1.4-1.1 2.6-2.6 2.6z"
            fill="#D8D8D8"
          />
        </g>
        <g>
          <path
            d="M734.3 395.5h-1.5v84.9h1.5v-84.9z"
            fill="url(#paint32_linear)"
          />
          <path
            d="M564.3 395.8h-1.5v84.9h1.5v-84.9z"
            fill="url(#paint33_linear)"
          />
          <path
            d="M754.4 395.8H540.3c-.7 0-1.3-.6-1.3-1.3v-5.4c0-.7.6-1.3 1.3-1.3h214.1c.7 0 1.3.6 1.3 1.3v5.4c.1.7-.5 1.3-1.3 1.3z"
            fill="url(#paint34_linear)"
          />
          <path
            d="M539 424.3v-35.2c0-.7.6-1.3 1.3-1.3h7.1c.7 0 1.3.6 1.3 1.3v35.2c0 .7-.6 1.3-1.3 1.3h-7.1c-.7.1-1.3-.5-1.3-1.3z"
            fill="url(#paint35_linear)"
          />
          <path
            d="M746 424.3v-35.2c0-.7.6-1.3 1.3-1.3h7.1c.7 0 1.3.6 1.3 1.3v35.2c0 .7-.6 1.3-1.3 1.3h-7.1c-.7.1-1.3-.5-1.3-1.3z"
            fill="url(#paint36_linear)"
          />
          <path
            d="M754.4 478.9H540.3c-.7 0-1.3.6-1.3 1.3v5.4c0 .7.6 1.3 1.3 1.3h214.1c.7 0 1.3-.6 1.3-1.3v-5.4c.1-.8-.5-1.3-1.3-1.3z"
            fill="url(#paint37_linear)"
          />
          <path
            d="M539 450.3v35.2c0 .7.6 1.3 1.3 1.3h7.1c.7 0 1.3-.6 1.3-1.3v-35.2c0-.7-.6-1.3-1.3-1.3h-7.1c-.7-.1-1.3.5-1.3 1.3z"
            fill="url(#paint38_linear)"
          />
          <path
            d="M746 450.3v35.2c0 .7.6 1.3 1.3 1.3h7.1c.7 0 1.3-.6 1.3-1.3v-35.2c0-.7-.6-1.3-1.3-1.3h-7.1c-.7-.1-1.3.5-1.3 1.3z"
            fill="url(#paint39_linear)"
          />
        </g>
        <path
          opacity=".28"
          d="M543.9 484.9a3.3 3.3 0 10.001-6.599 3.3 3.3 0 00-.001 6.599zM543.9 395.8a3.3 3.3 0 10.001-6.599 3.3 3.3 0 00-.001 6.599zM750.9 484.9a3.3 3.3 0 10.001-6.599 3.3 3.3 0 00-.001 6.599zM750.9 395.8a3.3 3.3 0 10.001-6.599 3.3 3.3 0 00-.001 6.599z"
          fill="#D8D8D8"
        />
      </g>
      <g className={HomeStyles.testTubeBubbles}>
        <path
          d="M595.9 418.2c.4.5.7 1.2.7 2 0 1.6-1.2 2.9-2.7 2.9-.5 0-1.1-.2-1.5-.5.5.6 1.2 1 2 1 1.5 0 2.7-1.3 2.7-2.9 0-1.1-.5-2-1.2-2.5z"
          fill="#EBE7D2"
        />
        <path
          d="M596.6 420.1c0-.8-.3-1.4-.7-2-.4-.3-.9-.5-1.5-.5-1.5 0-2.7 1.3-2.7 2.9 0 .8.3 1.4.7 2 .4.3.9.5 1.5.5 1.4.1 2.7-1.3 2.7-2.9z"
          fill="#EFE9E2"
        />
        <path
          opacity=".64"
          d="M592.5 420.6c0-1.1.8-1.9 1.9-1.9"
          stroke="#fff"
          strokeWidth=".56"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M601.1 425.4c.2.2.3.5.3.8 0 .6-.5 1.2-1.1 1.2-.2 0-.4-.1-.6-.2.2.2.5.4.8.4.6 0 1.1-.5 1.1-1.2 0-.5-.2-.8-.5-1z"
          fill="#EBE7D2"
        />
        <path
          d="M601.4 426.1c0-.3-.1-.6-.3-.8-.2-.1-.4-.2-.6-.2-.6 0-1.1.5-1.1 1.2 0 .3.1.6.3.8.2.1.4.2.6.2.6 0 1.1-.5 1.1-1.2z"
          fill="#EFE9E2"
        />
        <path
          opacity=".64"
          d="M599.8 426.3c0-.4.3-.8.7-.8"
          stroke="#fff"
          strokeWidth=".221"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M600.6 448.1c.3.4.6 1 .6 1.6 0 1.3-1 2.4-2.2 2.4-.4 0-.8-.1-1.2-.4.4.5 1 .8 1.6.8 1.2 0 2.2-1.1 2.2-2.4 0-.9-.4-1.6-1-2z"
          fill="#EBE7D2"
        />
        <path
          d="M601.2 449.6c0-.6-.2-1.2-.6-1.6-.3-.2-.8-.4-1.2-.4-1.2 0-2.2 1.1-2.2 2.4 0 .6.2 1.2.6 1.6.3.2.8.4 1.2.4 1.2 0 2.2-1 2.2-2.4z"
          fill="#EFE9E2"
        />
        <path
          opacity=".64"
          d="M597.9 450.1c0-.9.7-1.6 1.5-1.6"
          stroke="#fff"
          strokeWidth=".451"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M598.1 438.5c.3.4.5.9.5 1.4 0 1.2-.9 2.2-2 2.2-.4 0-.8-.1-1.1-.3.4.4.9.7 1.5.7 1.1 0 2-1 2-2.2 0-.7-.3-1.4-.9-1.8z"
          fill="#EBE7D2"
        />
        <path
          d="M598.6 440c0-.6-.2-1.1-.5-1.4-.3-.2-.7-.3-1.1-.3-1.1 0-2 1-2 2.2 0 .6.2 1.1.5 1.4.3.2.7.3 1.1.3 1.1-.1 2-1.1 2-2.2z"
          fill="#EFE9E2"
        />
        <path
          opacity=".64"
          d="M595.7 440.3c0-.8.6-1.4 1.4-1.4"
          stroke="#fff"
          strokeWidth=".411"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M592.4 400.4c.2.3.3.6.3 1 0 .8-.6 1.5-1.4 1.5-.3 0-.5-.1-.7-.2.3.3.6.5 1 .5.8 0 1.4-.7 1.4-1.5 0-.6-.2-1-.6-1.3z"
          fill="#EBE7D2"
        />
        <path
          d="M592.7 401.4c0-.4-.1-.7-.3-1-.2-.1-.5-.2-.7-.2-.8 0-1.4.7-1.4 1.5 0 .4.1.7.3 1 .2.1.5.2.7.2.8-.1 1.4-.7 1.4-1.5z"
          fill="#EFE9E2"
        />
        <path
          opacity=".64"
          d="M590.7 401.6c0-.5.4-1 .9-1"
          stroke="#fff"
          strokeWidth=".28"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M599.4 397.4c.2.3.4.6.4 1 0 .8-.7 1.5-1.6 1.5-.3 0-.6-.1-.8-.2.3.3.7.5 1.2.5.9 0 1.6-.7 1.6-1.5-.1-.5-.3-1-.8-1.3z"
          fill="#EBE7D2"
        />
        <path
          d="M599.8 398.4c0-.4-.1-.7-.4-1-.2-.1-.5-.2-.8-.2-.9 0-1.6.7-1.6 1.5 0 .4.1.7.4 1 .2.1.5.2.8.2.9 0 1.6-.7 1.6-1.5z"
          fill="#EFE9E2"
        />
        <path
          opacity=".64"
          d="M597.5 398.7c0-.5.5-1 1.1-1"
          stroke="#fff"
          strokeWidth=".298"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M593 377.9c.4.5.7 1.2.7 2 0 1.6-1.2 2.9-2.7 2.9-.5 0-1.1-.2-1.5-.5.5.6 1.2 1 2 1 1.5 0 2.7-1.3 2.7-2.9.1-1.1-.4-2-1.2-2.5z"
          fill="#EBE7D2"
        />
        <path
          d="M593.7 379.8c0-.8-.3-1.4-.7-2-.4-.3-.9-.5-1.5-.5-1.5 0-2.7 1.3-2.7 2.9 0 .8.3 1.4.7 2 .4.3.9.5 1.5.5 1.5.1 2.7-1.2 2.7-2.9z"
          fill="#EFE9E2"
        />
        <path
          opacity=".64"
          d="M589.7 380.4c0-1.1.8-1.9 1.9-1.9"
          stroke="#fff"
          strokeWidth=".56"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M599.9 366.6c.2.3.4.6.4 1.1 0 .9-.7 1.6-1.5 1.6-.3 0-.6-.1-.8-.3.3.3.7.5 1.1.5.8 0 1.5-.7 1.5-1.6 0-.6-.3-1.1-.7-1.3z"
          fill="#EBE7D2"
        />
        <path
          d="M600.3 367.6c0-.4-.1-.8-.4-1.1-.2-.2-.5-.3-.8-.3-.8 0-1.5.7-1.5 1.6 0 .4.1.8.4 1.1.2.2.5.3.8.3.8 0 1.5-.7 1.5-1.6z"
          fill="#EFE9E2"
        />
        <path
          opacity=".64"
          d="M598.1 367.9c0-.6.5-1 1-1"
          stroke="#fff"
          strokeWidth=".302"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g>
          <path
            d="M695.9 419c-.4.5-.7 1.2-.7 2 0 1.6 1.2 2.9 2.7 2.9.5 0 1.1-.2 1.5-.5-.5.6-1.2 1-2 1-1.5 0-2.7-1.3-2.7-2.9-.1-1 .4-1.9 1.2-2.5z"
            fill="#EBE7D2"
          />
          <path
            d="M695.2 421c0-.8.3-1.4.7-2 .4-.3.9-.5 1.5-.5 1.5 0 2.7 1.3 2.7 2.9 0 .8-.3 1.4-.7 2-.4.3-.9.5-1.5.5-1.5 0-2.7-1.3-2.7-2.9z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M699.2 421.5c0-1.1-.8-1.9-1.9-1.9"
            stroke="#fff"
            strokeWidth=".56"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g>
          <path
            d="M690.7 426.2c-.2.2-.3.5-.3.8 0 .6.5 1.2 1.1 1.2.2 0 .4-.1.6-.2-.2.2-.5.4-.8.4-.6 0-1.1-.5-1.1-1.2 0-.4.2-.8.5-1z"
            fill="#EBE7D2"
          />
          <path
            d="M690.4 427c0-.3.1-.6.3-.8.2-.1.4-.2.6-.2.6 0 1.1.5 1.1 1.2 0 .3-.1.6-.3.8-.2.1-.4.2-.6.2-.6 0-1.1-.6-1.1-1.2z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M692 427.2c0-.4-.3-.8-.7-.8"
            stroke="#fff"
            strokeWidth=".221"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g>
          <path
            d="M691.1 448.9c-.3.4-.6 1-.6 1.6 0 1.3 1 2.4 2.2 2.4.4 0 .8-.1 1.2-.4-.4.5-1 .8-1.6.8-1.2 0-2.2-1.1-2.2-2.4 0-.8.4-1.5 1-2z"
            fill="#EBE7D2"
          />
          <path
            d="M690.6 450.5c0-.6.2-1.2.6-1.6.3-.2.8-.4 1.2-.4 1.2 0 2.2 1.1 2.2 2.4 0 .6-.2 1.2-.6 1.6-.3.2-.8.4-1.2.4-1.2 0-2.2-1.1-2.2-2.4z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M693.8 450.9c0-.9-.7-1.6-1.5-1.6"
            stroke="#fff"
            strokeWidth=".451"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g>
          <path
            d="M693.7 439.4c-.3.4-.5.9-.5 1.4 0 1.2.9 2.2 2 2.2.4 0 .8-.1 1.1-.3-.4.4-.9.7-1.5.7-1.1 0-2-1-2-2.2-.1-.8.3-1.4.9-1.8z"
            fill="#EBE7D2"
          />
          <path
            d="M693.2 440.8c0-.6.2-1.1.5-1.4.3-.2.7-.3 1.1-.3 1.1 0 2 1 2 2.2 0 .6-.2 1.1-.5 1.4-.3.2-.7.3-1.1.3-1.1 0-2-1-2-2.2z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M696.1 441.2c0-.8-.6-1.4-1.4-1.4"
            stroke="#fff"
            strokeWidth=".411"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g>
          <path
            d="M699.4 401.3c-.2.3-.3.6-.3 1 0 .8.6 1.5 1.4 1.5.3 0 .5-.1.7-.2-.3.3-.6.5-1 .5-.8 0-1.4-.7-1.4-1.5-.1-.6.2-1.1.6-1.3z"
            fill="#EBE7D2"
          />
          <path
            d="M699 402.2c0-.4.1-.7.3-1 .2-.1.5-.2.7-.2.8 0 1.4.7 1.4 1.5 0 .4-.1.7-.3 1-.2.1-.5.2-.7.2-.8 0-1.4-.7-1.4-1.5z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M701 402.5c0-.5-.4-1-.9-1"
            stroke="#fff"
            strokeWidth=".28"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g>
          <path
            d="M692.3 398.3c-.2.3-.4.6-.4 1 0 .8.7 1.5 1.6 1.5.3 0 .6-.1.8-.2-.3.3-.7.5-1.2.5-.9 0-1.6-.7-1.6-1.5.1-.6.4-1 .8-1.3z"
            fill="#EBE7D2"
          />
          <path
            d="M691.9 399.3c0-.4.1-.7.4-1 .2-.1.5-.2.8-.2.9 0 1.6.7 1.6 1.5 0 .4-.1.7-.4 1-.2.1-.5.2-.8.2-.9 0-1.6-.7-1.6-1.5z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M694.2 399.6c0-.5-.5-1-1.1-1"
            stroke="#fff"
            strokeWidth=".298"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g>
          <path
            d="M698.8 378.7c-.4.5-.7 1.2-.7 2 0 1.6 1.2 2.9 2.7 2.9.5 0 1.1-.2 1.5-.5-.5.6-1.2 1-2 1-1.5 0-2.7-1.3-2.7-2.9-.1-1 .4-1.9 1.2-2.5z"
            fill="#EBE7D2"
          />
          <path
            d="M698.1 380.7c0-.8.3-1.4.7-2 .4-.3.9-.5 1.5-.5 1.5 0 2.7 1.3 2.7 2.9 0 .8-.3 1.4-.7 2-.4.3-.9.5-1.5.5-1.5 0-2.7-1.3-2.7-2.9z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M702.1 381.2c0-1.1-.8-1.9-1.9-1.9"
            stroke="#fff"
            strokeWidth=".56"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g>
          <path
            d="M691.8 367.4c-.2.3-.4.6-.4 1.1 0 .9.7 1.6 1.5 1.6.3 0 .6-.1.8-.3-.3.3-.7.5-1.1.5-.8 0-1.5-.7-1.5-1.6 0-.5.3-1 .7-1.3z"
            fill="#EBE7D2"
          />
          <path
            d="M691.5 368.5c0-.4.1-.8.4-1.1.2-.2.5-.3.8-.3.8 0 1.5.7 1.5 1.6 0 .4-.1.8-.4 1.1-.2.2-.5.3-.8.3-.9 0-1.5-.7-1.5-1.6z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M693.6 368.8c0-.6-.5-1-1-1"
            stroke="#fff"
            strokeWidth=".302"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g>
          <path
            d="M645.2 423.1c.4.5.7 1.2.7 2 0 1.6-1.2 2.9-2.7 2.9-.5 0-1.1-.2-1.5-.5.5.6 1.2 1 2 1 1.5 0 2.7-1.3 2.7-2.9.1-1.1-.5-2-1.2-2.5z"
            fill="#EBE7D2"
          />
          <path
            d="M645.9 425c0-.8-.3-1.4-.7-2-.4-.3-.9-.5-1.5-.5-1.5 0-2.7 1.3-2.7 2.9 0 .8.3 1.4.7 2 .4.3.9.5 1.5.5 1.5.1 2.7-1.2 2.7-2.9z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M641.9 425.6c0-1.1.8-1.9 1.9-1.9"
            stroke="#fff"
            strokeWidth=".56"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g>
          <path
            d="M649.6 434.2c.2.3.4.6.4 1.1 0 .9-.7 1.6-1.5 1.6-.3 0-.6-.1-.8-.3.3.3.7.5 1.1.5.8 0 1.5-.7 1.5-1.6 0-.6-.2-1.1-.7-1.3z"
            fill="#EBE7D2"
          />
          <path
            d="M650 435.2c0-.4-.1-.8-.4-1.1-.2-.2-.5-.3-.8-.3-.8 0-1.5.7-1.5 1.6 0 .4.1.8.4 1.1.2.2.5.3.8.3.9 0 1.5-.7 1.5-1.6z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M647.8 435.5c0-.6.5-1 1-1"
            stroke="#fff"
            strokeWidth=".302"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g>
          <path
            d="M651.2 419.3c.2.2.3.5.3.8 0 .7-.5 1.3-1.2 1.3-.2 0-.5-.1-.6-.2.2.3.5.4.9.4.7 0 1.2-.6 1.2-1.3-.1-.4-.3-.8-.6-1z"
            fill="#EBE7D2"
          />
          <path
            d="M651.5 420.2c0-.3-.1-.6-.3-.8-.2-.1-.4-.2-.6-.2-.7 0-1.2.6-1.2 1.3 0 .3.1.6.3.8.2.1.4.2.6.2.6-.1 1.2-.6 1.2-1.3z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M649.7 420.4c0-.5.4-.8.8-.8"
            stroke="#fff"
            strokeWidth=".244"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g>
          <path
            d="M651.5 444.6c.4.5.7 1.2.7 2 0 1.6-1.2 2.9-2.7 2.9-.5 0-1.1-.2-1.5-.5.5.6 1.2 1 2 1 1.5 0 2.7-1.3 2.7-2.9.1-1.1-.4-2-1.2-2.5z"
            fill="#EBE7D2"
          />
          <path
            d="M652.2 446.5c0-.8-.3-1.4-.7-2-.4-.3-.9-.5-1.5-.5-1.5 0-2.7 1.3-2.7 2.9 0 .8.3 1.4.7 2 .4.3.9.5 1.5.5 1.5.1 2.7-1.3 2.7-2.9z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M648.2 447c0-1.1.8-1.9 1.9-1.9"
            stroke="#fff"
            strokeWidth=".56"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g>
          <path
            d="M648.2 399.1c.2.3.4.6.4 1.1 0 .9-.7 1.6-1.5 1.6-.3 0-.6-.1-.8-.3.3.3.7.5 1.1.5.8 0 1.5-.7 1.5-1.6 0-.6-.3-1.1-.7-1.3z"
            fill="#EBE7D2"
          />
          <path
            d="M648.6 400.1c0-.4-.1-.8-.4-1.1-.2-.2-.5-.3-.8-.3-.8 0-1.5.7-1.5 1.6 0 .4.1.8.4 1.1.2.2.5.3.8.3.8 0 1.5-.7 1.5-1.6z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M646.4 400.4c0-.6.5-1 1-1"
            stroke="#fff"
            strokeWidth=".302"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g>
          <path
            d="M643.7 376.6c.2.3.4.6.4 1 0 .8-.7 1.5-1.5 1.5-.3 0-.6-.1-.8-.2.3.3.7.5 1.2.5.9 0 1.5-.7 1.5-1.5-.1-.6-.4-1.1-.8-1.3z"
            fill="#EBE7D2"
          />
          <path
            d="M644.1 377.5c0-.4-.1-.7-.4-1-.2-.1-.5-.2-.8-.2-.9 0-1.5.7-1.5 1.5 0 .4.1.7.4 1 .2.1.5.2.8.2.8 0 1.5-.6 1.5-1.5z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M641.8 377.8c0-.5.5-1 1-1"
            stroke="#fff"
            strokeWidth=".297"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g>
          <path
            d="M656.4 364.1c.4.5.7 1.2.7 2 0 1.6-1.2 2.9-2.7 2.9-.5 0-1.1-.2-1.5-.5.5.6 1.2 1 2 1 1.5 0 2.7-1.3 2.7-2.9.1-1.1-.4-2-1.2-2.5z"
            fill="#EBE7D2"
          />
          <path
            d="M657.1 366c0-.8-.3-1.4-.7-2-.4-.3-.9-.5-1.5-.5-1.5 0-2.7 1.3-2.7 2.9 0 .8.3 1.4.7 2 .4.3.9.5 1.5.5 1.5.1 2.7-1.2 2.7-2.9z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M653.1 366.6c0-1.1.8-1.9 1.9-1.9"
            stroke="#fff"
            strokeWidth=".56"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <path
        className={HomeStyles.cloud}
        d="M684.7 339c6 0 10.8-4.8 11-10.7h.4c11.2 0 20.3-9.1 20.3-20.3 0-11.1-9-20.2-20.1-20.3-1.2-10-9.8-17.8-20.1-17.8-6.8 0-12.8 3.4-16.5 8.5-3.7-3.9-8.9-6.4-14.8-6.4-11.2 0-20.3 9.1-20.3 20.3 0 1.8.2 3.6.7 5.2-4.3 3.7-7.1 9.2-7.1 15.4 0 11.2 9.1 20.3 20.3 20.3 5 0 9.6-1.8 13.2-4.9 3.5 3 8.1 4.9 13.2 4.9 3.4 0 6.5-.8 9.3-2.3 1.2 4.7 5.5 8.1 10.5 8.1z"
        fill="#EFE9E2"
      />
      <g className={HomeStyles.topBubbles}>
        <path
          d="M604.8 338.2c-.7.9-1.1 2-1.1 3.2 0 2.7 2 4.9 4.5 4.9.9 0 1.7-.3 2.4-.8-.8 1-2 1.6-3.4 1.6-2.5 0-4.5-2.2-4.5-4.9 0-1.6.8-3.1 2.1-4z"
          fill="#EBE7D2"
        />
        <path
          d="M603.6 341.4c0-1.2.4-2.4 1.1-3.2.7-.5 1.5-.8 2.4-.8 2.5 0 4.5 2.2 4.5 4.9 0 1.2-.4 2.4-1.1 3.2-.7.5-1.5.8-2.4.8-2.4 0-4.5-2.2-4.5-4.9z"
          fill="#EFE9E2"
        />
        <path
          opacity=".64"
          d="M610.3 342.3c0-1.8-1.4-3.2-3.1-3.2"
          stroke="#fff"
          strokeWidth=".926"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M698.8 347.2c-.7.9-1.1 2-1.1 3.2 0 2.7 2 4.9 4.5 4.9.9 0 1.7-.3 2.4-.8-.8 1-2 1.6-3.4 1.6-2.5 0-4.5-2.2-4.5-4.9 0-1.6.8-3.1 2.1-4z"
          fill="#EBE7D2"
        />
        <path
          d="M697.6 350.4c0-1.2.4-2.4 1.1-3.2.7-.5 1.5-.8 2.4-.8 2.5 0 4.5 2.2 4.5 4.9 0 1.2-.4 2.4-1.1 3.2-.7.5-1.5.8-2.4.8-2.4 0-4.5-2.2-4.5-4.9z"
          fill="#EFE9E2"
        />
        <path
          opacity=".64"
          d="M704.3 351.3c0-1.8-1.4-3.2-3.1-3.2"
          stroke="#fff"
          strokeWidth=".926"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g>
          <path
            d="M665.5 250.5c-.9 1.1-1.5 2.6-1.5 4.3 0 3.6 2.7 6.5 6 6.5 1.2 0 2.3-.4 3.2-1-1.1 1.3-2.7 2.2-4.5 2.2-3.3 0-6-2.9-6-6.5 0-2.4 1.1-4.4 2.8-5.5z"
            fill="#EBE7D2"
          />
          <path
            d="M663.9 254.8c0-1.7.6-3.2 1.5-4.3.9-.6 2.1-1 3.2-1 3.3 0 6 2.9 6 6.5 0 1.7-.6 3.2-1.5 4.3-.9.6-2.1 1-3.2 1-3.3-.1-6-3-6-6.5z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M672.8 255.9c0-2.4-1.8-4.3-4.1-4.3"
            stroke="#fff"
            strokeWidth="1.233"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g>
          <path
            d="M702.8 264.8c-.4.4-.6 1-.6 1.7 0 1.4 1 2.5 2.3 2.5.5 0 .9-.1 1.2-.4-.4.5-1 .8-1.7.8-1.3 0-2.3-1.1-2.3-2.5 0-.8.4-1.6 1.1-2.1z"
            fill="#EBE7D2"
          />
          <path
            d="M702.2 266.5c0-.6.2-1.2.6-1.7.4-.2.8-.4 1.2-.4 1.3 0 2.3 1.1 2.3 2.5 0 .6-.2 1.2-.6 1.7-.4.2-.8.4-1.2.4-1.3 0-2.3-1.1-2.3-2.5z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M705.6 266.9c0-.9-.7-1.6-1.6-1.6"
            stroke="#fff"
            strokeWidth=".473"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <path
        className={HomeStyles.cloud}
        d="M488.7 144c6 0 10.8-4.8 11-10.7h.4c11.2 0 20.3-9.1 20.3-20.3 0-11.1-9-20.2-20.1-20.3-1.3-9.9-9.8-17.7-20.2-17.7-6.8 0-12.8 3.4-16.5 8.5-3.7-3.9-8.9-6.4-14.8-6.4-11.2 0-20.3 9.1-20.3 20.3 0 1.8.2 3.6.7 5.2-4.3 3.7-7.1 9.2-7.1 15.4 0 11.2 9.1 20.3 20.3 20.3 5 0 9.6-1.8 13.2-4.9 3.5 3 8.1 4.9 13.2 4.9 3.4 0 6.5-.8 9.3-2.3 1.3 4.6 5.6 8 10.6 8z"
        fill="#EFE9E2"
      />
      <g>
        <path
          d="M452.8 269.9v-31.1h29.9v31.1c24.8 6.6 43.1 29.2 43.1 56.1 0 32.1-26 58.1-58.1 58.1s-58.1-26-58.1-58.1c.1-26.9 18.4-49.5 43.2-56.1z"
          fill="#F4F3F4"
        />
        <path
          d="M448 238.8h39.6c1.8 0 3.2-1.4 3.2-3.2v-1.8c0-1.8-1.4-3.2-3.2-3.2H448c-1.8 0-3.2 1.4-3.2 3.2v1.8c0 1.8 1.4 3.2 3.2 3.2z"
          fill="#F4F3F4"
        />
        <path
          opacity=".27"
          d="M452.8 269.9v-31.1h10v31.1c-24.8 6.6-43.1 29.2-43.1 56.1 0 30.4 23.3 55.3 53.1 57.8-1.6.1-3.3.2-5 .2-32.1 0-58.1-26-58.1-58.1 0-26.8 18.3-49.4 43.1-56z"
          fill="#D7D8D8"
        />
        <path
          d="M415.4 328.5c0 27.7 23.4 50.2 52.4 50.2s52.4-22.5 52.4-50.2"
          fill="url(#paint40_linear)"
        />
        <path
          d="M467.8 336.2c28.94 0 52.4-3.313 52.4-7.4s-23.46-7.4-52.4-7.4c-28.94 0-52.4 3.313-52.4 7.4s23.46 7.4 52.4 7.4z"
          fill="#F39FA6"
        />
        <path
          opacity=".27"
          d="M512.694 313.65c3.934-3.554 1.63-12.515-5.146-20.014-6.775-7.499-15.457-10.697-19.391-7.143-3.934 3.555-1.631 12.515 5.145 20.015 6.776 7.499 15.457 10.697 19.392 7.142zM516.2 331.8a6.5 6.5 0 100-13 6.5 6.5 0 000 13z"
          fill="#D8D8D8"
        />
      </g>
      <g>
        <path
          d="M392.3 478.7h6.8c2.9 0 5.2-2.4 5.2-5.2V216.7c0-2.9-2.4-5.2-5.2-5.2h-6.8c-2.9 0-5.2 2.4-5.2 5.2v256.8c-.1 2.8 2.3 5.2 5.2 5.2z"
          fill="url(#paint41_linear)"
        />
        <path
          d="M369.9 487.1h154.6c1.8 0 3.2-1.5 3.2-3.2v-4.4c0-7.2-5.9-13.1-13.1-13.1H379.8c-7.2 0-13.1 5.9-13.1 13.1v4.4c0 1.7 1.5 3.2 3.2 3.2z"
          fill="url(#paint42_linear)"
        />
        <path
          d="M527.8 257.6v-8.7c0-2.9-2.4-5.2-5.2-5.2H359c-2.9 0-5.2 2.4-5.2 5.2v8.7c0 2.9 2.4 5.2 5.2 5.2h163.5c2.9 0 5.3-2.3 5.3-5.2z"
          fill="url(#paint43_linear)"
        />
        <path
          opacity=".28"
          d="M395.7 256.5a3.3 3.3 0 10.001-6.599 3.3 3.3 0 00-.001 6.599z"
          fill="#D8D8D8"
        />
      </g>
      <g className={HomeStyles.testTubeBubbles}>
        <path
          d="M492 345.5c-.4.5-.7 1.2-.7 2 0 1.6 1.2 3 2.8 3 .6 0 1.1-.2 1.5-.5-.5.6-1.3 1-2.1 1-1.5 0-2.8-1.3-2.8-3 .1-1 .6-1.9 1.3-2.5z"
          fill="#EBE7D2"
        />
        <path
          d="M491.3 347.5c0-.8.3-1.5.7-2 .4-.3.9-.5 1.5-.5 1.5 0 2.8 1.3 2.8 3 0 .8-.3 1.5-.7 2-.4.3-.9.5-1.5.5-1.5 0-2.8-1.3-2.8-3z"
          fill="#EFE9E2"
        />
        <path
          opacity=".64"
          d="M495.4 348c0-1.1-.8-2-1.9-2"
          stroke="#fff"
          strokeWidth=".569"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M443.7 347c-.9 1-1.4 2.4-1.4 3.9 0 3.3 2.5 5.9 5.5 5.9 1.1 0 2.1-.3 3-.9-1 1.2-2.5 2-4.1 2-3 0-5.5-2.6-5.5-5.9 0-2.1 1-4 2.5-5z"
          fill="#EBE7D2"
        />
        <path
          d="M442.4 350.9c0-1.5.5-2.9 1.4-3.9.9-.6 1.9-.9 3-.9 3 0 5.5 2.6 5.5 5.9 0 1.5-.5 2.9-1.4 3.9-.9.6-1.9.9-3 .9-3.1 0-5.5-2.6-5.5-5.9z"
          fill="#EFE9E2"
        />
        <path
          opacity=".64"
          d="M450.4 352c0-2.1-1.7-3.9-3.7-3.9"
          stroke="#fff"
          strokeWidth="1.122"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M474 339.6c-.4.5-.7 1.2-.7 2 0 1.6 1.2 3 2.8 3 .6 0 1.1-.2 1.5-.5-.5.6-1.3 1-2.1 1-1.5 0-2.8-1.3-2.8-3 0-1.1.5-2 1.3-2.5z"
          fill="#EBE7D2"
        />
        <path
          d="M473.3 341.5c0-.8.3-1.5.7-2 .4-.3.9-.5 1.5-.5 1.5 0 2.8 1.3 2.8 3 0 .8-.3 1.5-.7 2-.4.3-.9.5-1.5.5-1.5 0-2.8-1.3-2.8-3z"
          fill="#EFE9E2"
        />
        <path
          opacity=".64"
          d="M477.4 342.1c0-1.1-.8-2-1.9-2"
          stroke="#fff"
          strokeWidth=".569"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M467.4 304.8c-.9 1.1-1.4 2.5-1.4 4 0 3.4 2.5 6.1 5.7 6.1 1.1 0 2.2-.4 3.1-1-1 1.2-2.5 2-4.2 2-3.1 0-5.7-2.7-5.7-6.1-.1-2 .9-3.9 2.5-5z"
          fill="#EBE7D2"
        />
        <path
          d="M465.9 308.9c0-1.6.5-3 1.4-4 .9-.6 1.9-1 3.1-1 3.1 0 5.7 2.7 5.7 6.1 0 1.6-.5 3-1.4 4-.9.6-1.9 1-3.1 1-3.1 0-5.7-2.8-5.7-6.1z"
          fill="#EFE9E2"
        />
        <path
          opacity=".64"
          d="M474.3 310c0-2.2-1.7-4-3.8-4"
          stroke="#fff"
          strokeWidth="1.158"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M459.1 280.6c-.9 1-1.4 2.4-1.4 3.9 0 3.2 2.4 5.9 5.5 5.9 1.1 0 2.1-.3 2.9-.9-1 1.2-2.5 2-4.1 2-3 0-5.5-2.6-5.5-5.9.1-2.1 1.1-3.9 2.6-5z"
          fill="#EBE7D2"
        />
        <path
          d="M457.7 284.5c0-1.5.5-2.9 1.4-3.9.9-.6 1.9-.9 2.9-.9 3 0 5.5 2.6 5.5 5.9 0 1.5-.5 2.9-1.4 3.9-.9.6-1.9.9-2.9.9-3 0-5.5-2.6-5.5-5.9z"
          fill="#EFE9E2"
        />
        <path
          opacity=".64"
          d="M465.8 285.6c0-2.1-1.7-3.9-3.7-3.9"
          stroke="#fff"
          strokeWidth="1.119"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g>
          <path
            d="M447.5 303.1c-.3.4-.5.9-.5 1.4 0 1.2.9 2.1 2 2.1.4 0 .8-.1 1.1-.3-.4.4-.9.7-1.5.7-1.1 0-2-.9-2-2.1 0-.8.4-1.5.9-1.8z"
            fill="#EBE7D2"
          />
          <path
            d="M447 304.5c0-.5.2-1 .5-1.4.3-.2.7-.3 1.1-.3 1.1 0 2 .9 2 2.1 0 .5-.2 1-.5 1.4-.3.2-.7.3-1.1.3-1.1 0-2-1-2-2.1z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M449.9 304.8c0-.8-.6-1.4-1.3-1.4"
            stroke="#fff"
            strokeWidth=".401"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g>
          <path
            d="M474 266.9c-.4.5-.7 1.2-.7 2 0 1.6 1.2 3 2.8 3 .6 0 1.1-.2 1.5-.5-.5.6-1.3 1-2.1 1-1.5 0-2.8-1.3-2.8-3 0-1.1.5-2 1.3-2.5z"
            fill="#EBE7D2"
          />
          <path
            d="M473.3 268.8c0-.8.3-1.5.7-2 .4-.3.9-.5 1.5-.5 1.5 0 2.8 1.3 2.8 3 0 .8-.3 1.5-.7 2-.4.3-.9.5-1.5.5-1.5 0-2.8-1.3-2.8-3z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M477.4 269.4c0-1.1-.8-2-1.9-2"
            stroke="#fff"
            strokeWidth=".569"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g>
          <path
            d="M463.8 220.2c-.7.8-1.1 2-1.1 3.2 0 2.7 2 4.8 4.5 4.8.9 0 1.7-.3 2.4-.8-.8 1-2 1.6-3.3 1.6-2.5 0-4.5-2.1-4.5-4.8-.1-1.6.7-3.1 2-4z"
            fill="#EBE7D2"
          />
          <path
            d="M462.6 223.4c0-1.2.4-2.3 1.1-3.2.7-.5 1.5-.8 2.4-.8 2.5 0 4.5 2.1 4.5 4.8 0 1.2-.4 2.3-1.1 3.2-.7.5-1.5.8-2.4.8-2.5 0-4.5-2.2-4.5-4.8z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M469.2 224.2c0-1.7-1.4-3.2-3-3.2"
            stroke="#fff"
            strokeWidth=".915"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g>
          <path
            d="M471.5 234.2c-.2.3-.4.7-.4 1.1 0 .9.7 1.6 1.5 1.6.3 0 .6-.1.8-.3-.3.3-.7.5-1.1.5-.8 0-1.5-.7-1.5-1.6 0-.5.3-1 .7-1.3z"
            fill="#EBE7D2"
          />
          <path
            d="M471.1 235.3c0-.4.1-.8.4-1.1.2-.2.5-.3.8-.3.8 0 1.5.7 1.5 1.6 0 .4-.1.8-.4 1.1-.2.2-.5.3-.8.3-.8 0-1.5-.7-1.5-1.6z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M473.3 235.6c0-.6-.5-1.1-1-1.1"
            stroke="#fff"
            strokeWidth=".307"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g>
          <path
            d="M483.9 358.1c-.2.2-.3.5-.3.9 0 .7.6 1.3 1.2 1.3.2 0 .5-.1.7-.2-.2.3-.6.4-.9.4-.7 0-1.2-.6-1.2-1.3-.1-.5.1-.9.5-1.1z"
            fill="#EBE7D2"
          />
          <path
            d="M483.6 358.9c0-.3.1-.7.3-.9.2-.1.4-.2.7-.2.7 0 1.2.6 1.2 1.3 0 .3-.1.7-.3.9-.2.1-.4.2-.7.2-.7.1-1.2-.5-1.2-1.3z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M485.4 359.2c0-.5-.4-.9-.8-.9"
            stroke="#fff"
            strokeWidth=".255"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g>
          <path
            d="M493.7 326.1c-.2.3-.4.7-.4 1.1 0 .9.7 1.6 1.5 1.6.3 0 .6-.1.8-.3-.3.3-.7.5-1.1.5-.8 0-1.5-.7-1.5-1.6 0-.5.2-1 .7-1.3z"
            fill="#EBE7D2"
          />
          <path
            d="M493.3 327.2c0-.4.1-.8.4-1.1.2-.2.5-.3.8-.3.8 0 1.5.7 1.5 1.6 0 .4-.1.8-.4 1.1-.2.2-.5.3-.8.3-.9 0-1.5-.7-1.5-1.6z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M495.5 327.5c0-.6-.5-1.1-1-1.1"
            stroke="#fff"
            strokeWidth=".307"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g>
          <path
            d="M449 325.1c-.4.5-.7 1.2-.7 2 0 1.6 1.2 3 2.8 3 .6 0 1.1-.2 1.5-.5-.5.6-1.3 1-2.1 1-1.5 0-2.8-1.3-2.8-3 0-1.1.6-2 1.3-2.5z"
            fill="#EBE7D2"
          />
          <path
            d="M448.3 327.1c0-.8.3-1.5.7-2 .4-.3.9-.5 1.5-.5 1.5 0 2.8 1.3 2.8 3 0 .8-.3 1.5-.7 2-.4.3-.9.5-1.5.5-1.5-.1-2.8-1.4-2.8-3z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M452.4 327.6c0-1.1-.8-2-1.9-2"
            stroke="#fff"
            strokeWidth=".569"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g>
          <path
            d="M462.8 191.2c-.7.8-1.1 2-1.1 3.2 0 2.7 2 4.8 4.5 4.8.9 0 1.7-.3 2.4-.8-.8 1-2 1.6-3.3 1.6-2.5 0-4.5-2.1-4.5-4.8-.1-1.6.7-3.1 2-4z"
            fill="#EBE7D2"
          />
          <path
            d="M461.6 194.4c0-1.2.4-2.3 1.1-3.2.7-.5 1.5-.8 2.4-.8 2.5 0 4.5 2.1 4.5 4.8 0 1.2-.4 2.3-1.1 3.2-.7.5-1.5.8-2.4.8-2.5 0-4.5-2.2-4.5-4.8z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M468.2 195.2c0-1.7-1.4-3.2-3-3.2"
            stroke="#fff"
            strokeWidth=".915"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g>
          <path
            d="M479.9 200.6c-1.1 1.3-1.8 3.1-1.8 5 0 4.1 3.1 7.5 7 7.5 1.4 0 2.7-.4 3.8-1.2-1.3 1.5-3.1 2.5-5.2 2.5-3.9 0-7-3.4-7-7.5 0-2.6 1.3-4.9 3.2-6.3z"
            fill="#EBE7D2"
          />
          <path
            d="M478.1 205.6c0-1.9.7-3.7 1.8-5 1.1-.7 2.4-1.2 3.8-1.2 3.9 0 7 3.4 7 7.5 0 1.9-.7 3.7-1.8 5-1.1.7-2.4 1.2-3.8 1.2-3.8 0-7-3.4-7-7.5z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M488.4 206.9c0-2.7-2.1-4.9-4.7-4.9"
            stroke="#fff"
            strokeWidth="1.427"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g>
          <path
            d="M477.1 171c-.5.6-.8 1.3-.8 2.2 0 1.8 1.4 3.3 3 3.3.6 0 1.2-.2 1.6-.5-.6.7-1.4 1.1-2.3 1.1-1.7 0-3-1.5-3-3.3.1-1.3.6-2.3 1.5-2.8z"
            fill="#EBE7D2"
          />
          <path
            d="M476.3 173.1c0-.8.3-1.6.8-2.2.5-.3 1-.5 1.6-.5 1.7 0 3 1.5 3 3.3 0 .8-.3 1.6-.8 2.2-.5.3-1 .5-1.6.5-1.6 0-3-1.5-3-3.3z"
            fill="#EFE9E2"
          />
          <path
            opacity=".64"
            d="M480.8 173.7c0-1.2-.9-2.1-2.1-2.1"
            stroke="#fff"
            strokeWidth=".619"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <g fill="#EFE9E2">
        <path
          className={HomeStyles.sparkles}
          d="M656.8 93.9c-14.4 0-9.8-17.5-9.8-17.5h-1.4s4.7 17.5-9.8 17.5c14.4 0 9.8 17.5 9.8 17.5h1.4s-4.7-17.5 9.8-17.5zM88.8 207.9c-14.4 0-9.8-17.5-9.8-17.5h-1.4s4.7 17.5-9.8 17.5c14.4 0 9.8 17.5 9.8 17.5H79s-4.7-17.5 9.8-17.5zM273.8 102.9c-14.4 0-9.8-17.5-9.8-17.5h-1.4s4.7 17.5-9.8 17.5c14.4 0 9.8 17.5 9.8 17.5h1.4s-4.7-17.5 9.8-17.5zM584.8 266.9c-14.4 0-9.8-17.5-9.8-17.5h-1.4s4.7 17.5-9.8 17.5c14.4 0 9.8 17.5 9.8 17.5h1.4s-4.7-17.5 9.8-17.5z"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="1008.28"
          y1="442.329"
          x2="1052.67"
          y2="365.439"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".431" stopColor="#81BEAF" />
          <stop offset=".76" stopColor="#B4DBD1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="1060.3"
          y1="347"
          x2="1060.3"
          y2="376.554"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9A5C39" />
          <stop offset="1" stopColor="#AD775B" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="1030.1"
          y1="471.6"
          x2="1030.1"
          y2="474.978"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9A5C39" />
          <stop offset="1" stopColor="#AD775B" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="992.7"
          y1="471.6"
          x2="992.7"
          y2="474.978"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9A5C39" />
          <stop offset="1" stopColor="#AD775B" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="1031.55"
          y1="364.2"
          x2="1031.55"
          y2="366.99"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9A5C39" />
          <stop offset="1" stopColor="#AD775B" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="795.768"
          y1="222.112"
          x2="823.796"
          y2="178.05"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#542A14" />
          <stop offset="1" stopColor="#C35E19" />
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="890.954"
          y1="217.611"
          x2="922.622"
          y2="167.62"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#542A14" />
          <stop offset="1" stopColor="#C35E19" />
        </linearGradient>
        <linearGradient
          id="paint7_linear"
          x1="819.757"
          y1="456.522"
          x2="771.102"
          y2="456.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".179" stopColor="#F3A0A6" />
          <stop offset=".569" stopColor="#E06071" />
        </linearGradient>
        <linearGradient
          id="paint8_linear"
          x1="832.968"
          y1="477.344"
          x2="853.456"
          y2="397.21"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".431" stopColor="#81BEAF" />
          <stop offset=".76" stopColor="#B4DBD1" />
        </linearGradient>
        <linearGradient
          id="paint9_linear"
          x1="912.373"
          y1="457.004"
          x2="961.038"
          y2="457.004"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".179" stopColor="#F3A0A6" />
          <stop offset=".569" stopColor="#E06071" />
        </linearGradient>
        <linearGradient
          id="paint10_linear"
          x1="364.49"
          y1="463.143"
          x2="384.59"
          y2="431.433"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#542A14" />
          <stop offset="1" stopColor="#C35E19" />
        </linearGradient>
        <linearGradient
          id="paint11_linear"
          x1="949.4"
          y1="274.1"
          x2="949.4"
          y2="282.665"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9A5C39" />
          <stop offset="1" stopColor="#AD775B" />
        </linearGradient>
        <linearGradient
          id="paint12_linear"
          x1="831.948"
          y1="201.592"
          x2="876.664"
          y2="131.155"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#542A14" />
          <stop offset="1" stopColor="#C35E19" />
        </linearGradient>
        <linearGradient
          id="paint13_linear"
          x1="916.99"
          y1="231.543"
          x2="937.09"
          y2="199.833"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#542A14" />
          <stop offset="1" stopColor="#C35E19" />
        </linearGradient>
        <linearGradient
          id="paint14_linear"
          x1="1027.45"
          y1="247.45"
          x2="1036.98"
          y2="172.482"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".431" stopColor="#81BEAF" />
          <stop offset=".76" stopColor="#B4DBD1" />
        </linearGradient>
        <linearGradient
          id="paint15_linear"
          x1="600"
          y1="507.4"
          x2="600"
          y2="517.644"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9A5C39" />
          <stop offset="1" stopColor="#AD775B" />
        </linearGradient>
        <linearGradient
          id="paint16_linear"
          x1="864.455"
          y1="390.768"
          x2="1043.24"
          y2="390.768"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".179" stopColor="#F3A0A6" />
          <stop offset=".569" stopColor="#E06071" />
        </linearGradient>
        <linearGradient
          id="paint17_linear"
          x1="876.695"
          y1="467.068"
          x2="1017.84"
          y2="467.068"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B0785D" />
          <stop offset=".485" stopColor="#AF775C" />
          <stop offset=".659" stopColor="#A97258" />
          <stop offset=".755" stopColor="#A26C53" />
        </linearGradient>
        <linearGradient
          id="paint18_linear"
          x1="337.841"
          y1="462.937"
          x2="357.224"
          y2="432.358"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#542A14" />
          <stop offset="1" stopColor="#C35E19" />
        </linearGradient>
        <linearGradient
          id="paint19_linear"
          x1="973.181"
          y1="141.13"
          x2="968.587"
          y2="128.392"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#2EAC68" />
          <stop offset="1" stopColor="#3AAA35" />
        </linearGradient>
        <linearGradient
          id="paint20_linear"
          x1="974.193"
          y1="133.944"
          x2="980.332"
          y2="141.005"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#2EAC68" />
          <stop offset="1" stopColor="#3AAA35" />
        </linearGradient>
        <linearGradient
          id="paint21_linear"
          x1="1008.2"
          y1="124.098"
          x2="1014.09"
          y2="134.326"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#2EAC68" />
          <stop offset="1" stopColor="#3AAA35" />
        </linearGradient>
        <linearGradient
          id="paint22_linear"
          x1="1015.39"
          y1="96.319"
          x2="1023.72"
          y2="106.996"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#2EAC68" />
          <stop offset="1" stopColor="#3AAA35" />
        </linearGradient>
        <linearGradient
          id="paint23_linear"
          x1="992.968"
          y1="92.091"
          x2="1002.49"
          y2="96.475"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#2EAC68" />
          <stop offset="1" stopColor="#3AAA35" />
        </linearGradient>
        <linearGradient
          id="paint24_linear"
          x1="998.676"
          y1="119.791"
          x2="1008.8"
          y2="128.789"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#2EAC68" />
          <stop offset="1" stopColor="#3AAA35" />
        </linearGradient>
        <linearGradient
          id="paint25_linear"
          x1="975.442"
          y1="125.625"
          x2="982.968"
          y2="114.368"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#2EAC68" />
          <stop offset="1" stopColor="#3AAA35" />
        </linearGradient>
        <linearGradient
          id="paint26_linear"
          x1="609.378"
          y1="441.224"
          x2="585.001"
          y2="441.224"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".179" stopColor="#F3A0A6" />
          <stop offset=".569" stopColor="#E06071" />
        </linearGradient>
        <linearGradient
          id="paint27_linear"
          x1="594.141"
          y1="415.737"
          x2="602.737"
          y2="400.847"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".179" stopColor="#F39FA6" />
          <stop offset=".569" stopColor="#DF6171" />
        </linearGradient>
        <linearGradient
          id="paint28_linear"
          x1="680.309"
          y1="464.783"
          x2="722.134"
          y2="392.34"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".239" stopColor="#F3A0A6" />
          <stop offset=".676" stopColor="#F0EBE4" />
        </linearGradient>
        <linearGradient
          id="paint29_linear"
          x1="693.394"
          y1="415.737"
          x2="701.991"
          y2="400.847"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".239" stopColor="#F3A0A6" />
          <stop offset=".676" stopColor="#F0EBE4" />
        </linearGradient>
        <linearGradient
          id="paint30_linear"
          x1="632.026"
          y1="464.783"
          x2="673.85"
          y2="392.34"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".431" stopColor="#81BEAF" />
          <stop offset=".76" stopColor="#B4DBD1" />
        </linearGradient>
        <linearGradient
          id="paint31_linear"
          x1="645.111"
          y1="415.737"
          x2="653.707"
          y2="400.847"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".431" stopColor="#81BEAF" />
          <stop offset=".76" stopColor="#B4DBD1" />
        </linearGradient>
        <linearGradient
          id="paint32_linear"
          x1="647.355"
          y1="387.8"
          x2="647.355"
          y2="409.399"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9A5C39" />
          <stop offset="1" stopColor="#AD775B" />
        </linearGradient>
        <linearGradient
          id="paint33_linear"
          x1="647.355"
          y1="387.8"
          x2="647.355"
          y2="409.399"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9A5C39" />
          <stop offset="1" stopColor="#AD775B" />
        </linearGradient>
        <linearGradient
          id="paint34_linear"
          x1="647.355"
          y1="387.8"
          x2="647.355"
          y2="409.399"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9A5C39" />
          <stop offset="1" stopColor="#AD775B" />
        </linearGradient>
        <linearGradient
          id="paint35_linear"
          x1="647.355"
          y1="387.8"
          x2="647.355"
          y2="409.399"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9A5C39" />
          <stop offset="1" stopColor="#AD775B" />
        </linearGradient>
        <linearGradient
          id="paint36_linear"
          x1="647.355"
          y1="387.8"
          x2="647.355"
          y2="409.399"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9A5C39" />
          <stop offset="1" stopColor="#AD775B" />
        </linearGradient>
        <linearGradient
          id="paint37_linear"
          x1="647.355"
          y1="387.8"
          x2="647.355"
          y2="409.399"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9A5C39" />
          <stop offset="1" stopColor="#AD775B" />
        </linearGradient>
        <linearGradient
          id="paint38_linear"
          x1="647.355"
          y1="387.8"
          x2="647.355"
          y2="409.399"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9A5C39" />
          <stop offset="1" stopColor="#AD775B" />
        </linearGradient>
        <linearGradient
          id="paint39_linear"
          x1="647.355"
          y1="387.8"
          x2="647.355"
          y2="409.399"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9A5C39" />
          <stop offset="1" stopColor="#AD775B" />
        </linearGradient>
        <linearGradient
          id="paint40_linear"
          x1="520.107"
          y1="353.62"
          x2="415.405"
          y2="353.62"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".179" stopColor="#F3A0A6" />
          <stop offset=".569" stopColor="#E06071" />
        </linearGradient>
        <linearGradient
          id="paint41_linear"
          x1="395.698"
          y1="211.5"
          x2="395.698"
          y2="269.736"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9A5C39" />
          <stop offset="1" stopColor="#AD775B" />
        </linearGradient>
        <linearGradient
          id="paint42_linear"
          x1="447.2"
          y1="466.4"
          x2="447.2"
          y2="470.912"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9A5C39" />
          <stop offset="1" stopColor="#AD775B" />
        </linearGradient>
        <linearGradient
          id="paint43_linear"
          x1="440.8"
          y1="243.7"
          x2="440.8"
          y2="247.863"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9A5C39" />
          <stop offset="1" stopColor="#AD775B" />
        </linearGradient>
      </defs>
    </svg>
  )
}
