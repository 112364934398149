import React from "react"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

//styles
import HomeStyles from "./HomePage.module.scss"

//Components
import HomePageMobile from "./HomePageMobile"
import HomePageDesktop from "./HomePageDesktop"

export default function HomePage() {
  const breakpoints = useBreakpoint()
  return (
    <>
      <div className={HomeStyles.container}>
        <p className={HomeStyles.title}>Developer | ChemToCode</p>
        {breakpoints.md ? <HomePageMobile /> : <HomePageDesktop />}
      </div>
    </>
  )
}
