import React from "react"
import HomePage from "../components/Home/HomePage"
import Seo from "../components/Seo/Seo"
import SocialCard from "../../static/uploads/socialCard.png"

export default function Home({ location }) {
  return (
    <>
      <Seo
        image={SocialCard}
        title="ChemToCode | Home"
        pathname={location.pathname}
      />
      <HomePage />
    </>
  )
}
